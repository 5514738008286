import { useEffect, useState } from "react";
import { scaleOrdinal, scaleSequential } from "d3-scale";
import { interpolateRainbow } from "d3-scale-chromatic";
import { useQuery } from "react-query";
import axios from "axios";
import { INIT_STYLE_VALUES } from "../../constants";

const colors = (specifier) => {
  var n = (specifier.length / 6) | 0,
    colors = new Array(n),
    i = 0;
  while (i < n) colors[i] = "#" + specifier.slice(i * 6, ++i * 6);
  return colors;
};

const colorsString20 =
  "1F77B4AEC7E8FF7F0EFFBB782CA02C98DF8AD62728FF98969467BDC5B0D58C564BC49C94E377C2F7B6D27F7F7FC7C7C7BCBD22DBDB8D17BECF9EDAE5";

const buildScale = (values) => {
  const scale =
    values.length <= 20
      ? scaleOrdinal(colors(colorsString20))
      : scaleSequential(interpolateRainbow);

  const degree = 100 / values.length / 100;

  return values.reduce((acc, val, i) => {
    acc.push([val]);

    if (values.length <= 20) {
      acc.push(scale(val));
    } else {
      acc.push(scale(i * degree));
    }

    return acc;
  }, []);
};

const useLayerStyles = ({ onLayerStyleChange }) => {
  const [styleValues, setStyleValues] = useState(INIT_STYLE_VALUES);

  const { data } = useQuery(
    ["filterData2"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/public-map/filters`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data) {
      setStyleValues((prevState) => ({
        ...prevState,
        locationTypes: {
          ...prevState.locationTypes,
          paint: {
            "circle-color": [
              "match",
              ["get", "locationtype"],
              ...buildScale(data.locationTypes.map((item) => item.display)),
              "#fff",
            ],
          },
        },
      }));
    }
  }, [data]); //eslint-disable-line
  const [activeStyle, setActiveStyle] = useState(styleValues.default);
  const styleOptions = Object.entries(styleValues).map(([key, value]) => ({
    display: value.name,
    value: key,
  }));

  const handleActiveStyle = (name) => {
    setActiveStyle(styleValues[name]);
    onLayerStyleChange(styleValues[name]);
  };

  return {
    activeStyle,
    handleActiveStyle,
    styleOptions,
    styleValues,
  };
};

export default useLayerStyles;
