import React, { useCallback, useMemo, useState } from "react";
import { useRecentDataSummary } from "../../hooks/useRecentDataSummary";
import {
  BorderBox,
  HeaderBox,
  ScrollableGridContainer,
} from "../../styledComponents";
import { Typography, Button, withWidth, isWidthDown } from "@material-ui/core";
import LineChartSection from "./LineChartSection";
import PercentFullSection from "./PercentFullSection";
import MonthlyBarChartSection from "./MonthlyBarChartSection";
import AnnualBarChartSection from "./AnnualBarChartSection";
import { useTransformedFacilityData } from "../../hooks/useTransformedFacilityData";

const WIDGETS_MAP = {
  "002_lastfew": LineChartSection,
  "004_pctfull": PercentFullSection,
  "003_monthly": MonthlyBarChartSection,
  "000_ytd": AnnualBarChartSection,
};

const DEFAULT_WIDGET_ORDER = [
  "002_lastfew",
  "004_pctfull",
  "003_monthly",
  "000_ytd",
];

const RecentDataSummary = React.memo(({ widgetOrder, facilityNdx, width }) => {
  const { lastFewData, monthlyData, ytdData, pctFullData, error } =
    useRecentDataSummary(facilityNdx);
  const { transformedLastFewData, transformedMonthlyData, transformedYtdData } =
    useTransformedFacilityData({ lastFewData, monthlyData, ytdData });

  const [customWrap, setCustomWrap] = useState(true);
  const toggleCustomWrap = useCallback(
    () => setCustomWrap((prev) => !prev),
    []
  );

  const isMobileView = isWidthDown("md", width);

  const getWidgetData = (id) => {
    return (
      {
        "002_lastfew": {
          data: transformedLastFewData,
          isLoading: lastFewData.isFetching,
        },
        "004_pctfull": {
          data: pctFullData.data,
          isLoading: pctFullData.isFetching,
        },
        "003_monthly": {
          data: transformedMonthlyData,
          isLoading: monthlyData.isFetching,
        },
        "000_ytd": { data: transformedYtdData, isLoading: ytdData.isFetching },
      }[id] || { data: null, isLoading: false }
    );
  };

  const orderedWidgets = useMemo(() => {
    const orderedFromWidgetOrder = widgetOrder
      .filter((id) => WIDGETS_MAP[id])
      .map((id) => ({ id, Component: WIDGETS_MAP[id] }));

    const remainingWidgets = DEFAULT_WIDGET_ORDER.filter(
      (id) => !widgetOrder.includes(id)
    ).map((id) => ({ id, Component: WIDGETS_MAP[id] }));

    return [...orderedFromWidgetOrder, ...remainingWidgets];
  }, [widgetOrder]);

  if (error) {
    return <p>Error fetching recent data summary: {error.message}</p>;
  }

  return (
    <BorderBox>
      <HeaderBox>
        <Typography variant="h5">Recent Data Summary</Typography>
        {!isMobileView && (
          <Button variant="outlined" color="primary" onClick={toggleCustomWrap}>
            {customWrap ? "Expand" : "Collapse"}
          </Button>
        )}
      </HeaderBox>
      <ScrollableGridContainer container spacing={3} customWrap={customWrap}>
        {orderedWidgets.map(({ id, Component }) => {
          const { data, isLoading } = getWidgetData(id);
          return (
            <Component
              key={id}
              customWrap={customWrap}
              data={data}
              isLoading={isLoading}
            />
          );
        })}
      </ScrollableGridContainer>
    </BorderBox>
  );
});

export default withWidth()(RecentDataSummary);
