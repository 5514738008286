/* eslint-disable import/first */
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ROUTES } from "../constants";
import {
  Activity,
  BarChart2,
  Map as MapIcon,
  Monitor,
  Settings,
  Smartphone,
  Users,
} from "react-feather";
import {
  Assignment,
  BatteryUnknown,
  PhotoLibrary,
  PlayCircleOutline,
  Warning,
  YoutubeSearchedFor,
} from "@material-ui/icons";

import async from "../components/Async";

import AuthGuard from "../components/AuthGuard";
import AdminGuard from "../components/AdminGuard";
import AdminVisibilityFilter from "../components/AdminVisibilityFilter";
import UserVisibilityFilter from "../components/UserVisibilityFilter";

import PublicMap from "../pages/publicMap";
import Landing from "../pages/presentation/Landing";
import Facility from "../pages/facilities/[id]";
import Facilities from "../pages/facilities";
import TimeSeriesComparison from "../pages/dataAccess/timeSeries/TimeSeriesComparison";
import AlertsManagement from "../pages/dataAccess/systemManagement/AlertsManagement";
import Groups from "../pages/dataAccess/systemManagement/Groups";
import GroupsToContacts from "../pages/dataAccess/associations/GroupsToContacts";
import FacilitiesToLocations from "../pages/dataAccess/associations/FacilitiesToLocations";
import LocationsSetup from "../pages/dataAccess/systemManagement/LocationsSetup/LocationsSetup";
import FacilitiesSetup from "../pages/dataAccess/systemManagement/FacilitiesSetup/FacilitesSetup";
import ImportsConfiguration from "../pages/dataAccess/systemManagement/ImportsConfiguration";
import SmsHelp from "../pages/dataAccess/systemManagement/SmsHelp";
import BatteriesStatusTable from "../pages/dataAccess/Reports/BatteriesStatusTable";
import SitesSummaryTable from "../pages/dataAccess/Reports/SitesSummaryTable";
import UsersHome from "../pages/users/UsersHome";
import FacilitiesDownload from "../pages/dataAccess/queryAndDownload/facilities";
import Contacts from "../pages/dataAccess/systemManagement/Contacts";
import Western from "../pages/riverPhotos/Western";
import Empire from "../pages/riverPhotos/Empire";
import RiversideDam from "../pages/riverPhotos/RiversideDam";
import RiversideSandGate from "../pages/riverPhotos/RiversideSandGate";
import MobileTracker from "../pages/dataAccess/Reports/MobileTracker";
import IgnitionDemo from "../pages/admin/IgnitionDemo";
import NonUserGuard from "../components/NonUserGuard";
import NonUserVisibilityFilter from "../components/NonUserVisibilityFilter";
import AllDataUserGuard from "../components/AllDataUserGuard";
import AllDataUserVisibilityFilter from "../components/AllDataUserVisibilityFilter";

const Account = async(() => import("../pages/pages/Account"));
const Profile = async(() => import("../pages/pages/Profile"));

const landingRoutes = {
  id: "Landing Page",
  path: "/login",
  header: "Docs",
  icon: <Monitor />,
  component: Landing,
  children: null,
};

const publicMapRoutes = {
  header: "Dashboards",
  id: "Map Explorer",
  icon: <MapIcon />,
  path: ROUTES.PUBLIC_MAP,
  name: "Map Explore",
  component: PublicMap,
};

const mobileDashRoute = {
  id: "Mobile Dashboard",
  path: "/dashboards/mobile-dashboard",
  icon: <Smartphone />,
  component: MobileTracker,
  children: null,
  guard: AllDataUserGuard,
  visibilityFilter: AllDataUserVisibilityFilter,
};

const facilityPagesRoutes = {
  id: "Facility Pages",
  open: true,
  icon: <BarChart2 />,
  children: [
    {
      path: "/facility-pages/:id",
      name: "Facility",
      component: Facility,
    },
    {
      path: "/facility-pages",
      name: "Facilities",
      component: Facilities,
    },
  ],
  guard: AuthGuard,
  visibilityFilter: () => false,
};

const queryAndDownloadRoute = {
  header: "Data Access",
  id: "Query & Download",
  icon: <YoutubeSearchedFor />,
  path: "/data-access/query-and-download/facilities",
  name: "Facility Data",
  component: FacilitiesDownload,
  guard: AuthGuard,
  visibilityFilter: UserVisibilityFilter,
};

const timeSeriesComparisonRoute = {
  id: "Time Series Comparisons",
  icon: <Activity />,
  path: "/data-access/time-series-comparisons",
  name: "Time Series Comparisons",
  component: TimeSeriesComparison,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const batteriesRoute = {
  id: "Batteries Status",
  icon: <BatteryUnknown />,
  path: "/data-access/batteries-status-table",
  name: "Batteries Status Last 7 Days Stats",
  component: BatteriesStatusTable,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const siteSummaryRoute = {
  id: "Site Summary Table",
  icon: <Assignment />,
  path: "/data-access/site-summary-table",
  name: "Site Summary Table",
  component: SitesSummaryTable,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const usersManagementRoutes = {
  header: "Admin",
  id: "Users Management",
  icon: <Users />,
  path: "/admin/users-management",
  name: "Users Management",
  component: UsersHome,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const alertsManagementRoutes = {
  id: "Alerts Management",
  icon: <Warning />,
  children: [
    {
      path: "/data-access/system-management/alerts-management",
      name: "Alerts Management",
      component: AlertsManagement,
    },
    {
      path: "/data-access/system-management/contacts",
      name: "Contacts",
      component: Contacts,
    },
    {
      path: "/data-access/system-management/groups",
      name: "Contact Groups",
      component: Groups,
    },
    {
      path: "/data-access/system-management/associate-contacts-and-groups",
      name: "Associate Groups with Contacts",
      component: GroupsToContacts,
    },
    {
      path: "/data-access/system-management/sms-help",
      name: "SMS Help",
      component: SmsHelp,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const systemManagementRoutes = {
  id: "System Management",
  icon: <Settings />,
  children: [
    {
      path: "/data-access/system-management/associate-facilities-and-locations",
      name: "Associate Facilities with Locations",
      component: FacilitiesToLocations,
    },
    {
      path: "/data-access/system-management/locations-setup",
      name: "Locations Setup",
      component: LocationsSetup,
    },
    {
      path: "/data-access/system-management/facilities-setup",
      name: "Facilities Setup",
      component: FacilitiesSetup,
    },
    {
      path: "/data-access/system-management/imports-configuration",
      name: "Imports Configuration",
      component: ImportsConfiguration,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const ignitionDemoRoute = {
  id: "Ignition Demo",
  icon: <PlayCircleOutline />,
  path: "/admin/ignition-demo",
  name: "Ignition Demo",
  component: IgnitionDemo,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const westernPhotosRoute = {
  header: "River Photos",
  id: "Western",
  open: true,
  icon: <PhotoLibrary />,
  path: "/river-photos/western",
  name: "Facility",
  component: Western,
  guard: NonUserGuard,
  visibilityFilter: NonUserVisibilityFilter,
};

const empirePhotosRoute = {
  id: "Empire",
  open: true,
  icon: <PhotoLibrary />,
  path: "/river-photos/empire",
  name: "Empire",
  component: Empire,
  guard: NonUserGuard,
  visibilityFilter: NonUserVisibilityFilter,
};

const riversideDamPhotosRoute = {
  id: "Riverside - Dam",
  open: true,
  icon: <PhotoLibrary />,
  path: "/river-photos/riverside-dam",
  name: "Riverside - Dam",
  component: RiversideDam,
  guard: NonUserGuard,
  visibilityFilter: NonUserVisibilityFilter,
};

const riversideSandGatePhotosRoute = {
  id: "Riverside - Sand Gate",
  open: true,
  icon: <PhotoLibrary />,
  path: "/river-photos/riverside-sand-gate",
  name: "Riverside - Sand Gate",
  component: RiversideSandGate,
  guard: NonUserGuard,
  visibilityFilter: NonUserVisibilityFilter,
};

const accountRoutes = {
  id: "Account",
  path: "/account",
  name: "Account",
  header: "Pages",
  icon: <Users />,
  component: Account,
  guard: AuthGuard,
  children: [
    {
      path: ROUTES.USER_PROFILE,
      name: "Profile",
      component: Profile,
    },
    {
      path: "/auth/logout",
      name: "Logout",
      component: function Logout() {
        const { logout } = useAuth0();
        logout();
      },
    },
  ],
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  facilityPagesRoutes,
  queryAndDownloadRoute,
  timeSeriesComparisonRoute,
  batteriesRoute,
  siteSummaryRoute,
  usersManagementRoutes,
  alertsManagementRoutes,
  systemManagementRoutes,
  ignitionDemoRoute,
  westernPhotosRoute,
  empirePhotosRoute,
  riversideDamPhotosRoute,
  riversideSandGatePhotosRoute,
  accountRoutes,
];

export const dashboardMaxContentLayoutRoutes = [
  publicMapRoutes,
  mobileDashRoute,
];

// Routes using the Auth layout
export const authLayoutRoutes = [accountRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes using the full screen map layout
export const fullscreenMapRoutes = [];

// Routes visible in the sidebar
export const sidebarRoutes = [
  publicMapRoutes,
  mobileDashRoute,
  queryAndDownloadRoute,
  timeSeriesComparisonRoute,
  batteriesRoute,
  siteSummaryRoute,
  usersManagementRoutes,
  alertsManagementRoutes,
  systemManagementRoutes,
  ignitionDemoRoute,
  westernPhotosRoute,
  empirePhotosRoute,
  riversideDamPhotosRoute,
  riversideSandGatePhotosRoute,
];
