import React, { useMemo } from "react";
import { DataLoader } from "../../styledComponents";
import TimeseriesGraph from "../../components/chartJS/TimeseriesGraph";
import {
  PARAMETERS_TO_REVERSE_AXIS,
  TIMESERIES_GRAPH_CONFIG,
} from "../../constants";
import { createTimeseriesDataset } from "../../utils";
import { Typography } from "@material-ui/core";

const adjustAxisLimits = (min, max) => {
  if (min == null || max == null) return { min, max };
  const buffer = 3;
  return {
    min: Math.max(0, Math.floor(min) - buffer),
    max: Math.ceil(max) + buffer,
  };
};

const processAxisData = (axisData, yAxisId) => {
  let minY = Infinity,
    maxY = -Infinity,
    reverseAxis = false;
  const datasets =
    axisData?.reduce((acc, data) => {
      const dataset = createTimeseriesDataset({
        location: data,
        yAxisId,
        config: TIMESERIES_GRAPH_CONFIG,
      });
      dataset.data.forEach(({ y }) => {
        minY = Math.min(minY, y);
        maxY = Math.max(maxY, y);
      });

      if (
        data.some((record) =>
          PARAMETERS_TO_REVERSE_AXIS.includes(record.parameterNdx)
        )
      ) {
        reverseAxis = true;
      }

      acc.push(dataset);
      return acc;
    }, []) ?? [];

  return { ...adjustAxisLimits(minY, maxY), datasets, reverseAxis };
};

const filterDataBySelectedItems = (data, selectedItems) => {
  if (!data) return {};

  return Object.entries(data).reduce((acc, [key, value]) => {
    if ([0, -1, ...selectedItems].includes(parseInt(key, 10))) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

const useGraphData = (nestedData) =>
  useMemo(() => {
    const result = Object.keys(nestedData || {}).reduce(
      (acc, locationId) => {
        const locationData = nestedData[locationId];

        const leftAxis = processAxisData(locationData?.yL, "yL");
        const rightAxis = processAxisData(locationData?.yR, "yR");

        acc.datasets = [
          ...acc.datasets,
          ...leftAxis.datasets,
          ...rightAxis.datasets,
        ];
        acc.yLMin = Math.min(acc.yLMin, leftAxis.min);
        acc.yLMax = Math.max(acc.yLMax, leftAxis.max);
        acc.yRMin = Math.min(acc.yRMin, rightAxis.min);
        acc.yRMax = Math.max(acc.yRMax, rightAxis.max);

        if (!acc.yLLabel && leftAxis.datasets.length > 0) {
          acc.yLLabel = leftAxis.datasets[0].axisLabel;
        }
        if (!acc.yRLabel && rightAxis.datasets.length > 0) {
          acc.yRLabel = rightAxis.datasets[0].axisLabel;
        }
        acc.yLReverseAxis = acc.yLReverseAxis || leftAxis.reverseAxis;
        acc.yRReverseAxis = acc.yRReverseAxis || rightAxis.reverseAxis;

        return acc;
      },
      {
        datasets: [],
        yLMin: Infinity,
        yLMax: -Infinity,
        yRMin: Infinity,
        yRMax: -Infinity,
        yLLabel: "",
        yRLabel: "",
        yLReverseAxis: false,
        yRReverseAxis: false,
      }
    );

    const sortedDatasets = result.datasets.sort((a, b) => {
      // First, sort by yAxisID
      if (a.yAxisID !== b.yAxisID) {
        return a.yAxisID.localeCompare(b.yAxisID);
      }

      // If yAxisID is the same, then sort by label
      if (a.label === "Empty") return 1;
      if (b.label === "Empty") return -1;
      if (a.label === "Full") return b.label === "Empty" ? -1 : 1;
      if (b.label === "Full") return a.label === "Empty" ? 1 : -1;

      return a.label.localeCompare(b.label);
    });

    return {
      datasets: sortedDatasets,
      yLMin: result.yLMin,
      yLMax: result.yLMax,
      yRMin: result.yRMin,
      yRMax: result.yRMax,
      yLLabel: result.yLLabel,
      yRLabel: result.yRLabel,
      yLReverseAxis: result.yLReverseAxis,
      yRReverseAxis: result.yRReverseAxis,
    };
  }, [nestedData]);

const LineGraphSection = React.memo(
  ({
    data,
    isLoading,
    selectedItems,
    timeInterval,
    yRMin: propYRMin,
    yRMax: propYRMax,
  }) => {
    const filteredData = filterDataBySelectedItems(data, selectedItems);

    const {
      datasets,
      yLMin,
      yLMax,
      yRMin,
      yRMax,
      yLLabel,
      yRLabel,
      yLReverseAxis,
      yRReverseAxis,
    } = useGraphData(filteredData);
    const hasData = !!(data && Object.values(data)?.length);

    const noDataForSelectedItems = !datasets?.length;

    const { min: adjustedYRMin, max: adjustedYRMax } = adjustAxisLimits(
      propYRMin,
      propYRMax
    );

    return (
      <DataLoader isLoading={isLoading} isData={hasData}>
        {noDataForSelectedItems ? (
          <Typography
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            No data available
          </Typography>
        ) : (
          <TimeseriesGraph
            data={{ datasets }}
            isLoading={isLoading}
            yLLabel={yLLabel ?? null}
            yRLabel={yRLabel ?? null}
            yLMin={yLMin}
            yLMax={yLMax}
            yRMin={adjustedYRMin ?? yRMin}
            yRMax={adjustedYRMax ?? yRMax}
            yRReverseAxis={yRReverseAxis}
            yLReverseAxis={yLReverseAxis}
            timeInterval={timeInterval}
          />
        )}
      </DataLoader>
    );
  }
);

export default LineGraphSection;
