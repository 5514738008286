import { useMemo } from "react";

const getSymbolStylesFrom = (sources, sourceIdsToCheck) => {
  return sources.reduce((acc, source) => {
    if (sourceIdsToCheck.includes(source.id) && source.data?.features) {
      source.data.features.forEach((feature) => {
        const symbolStyleData = {
          mapSymbolCategoryNdx: feature.properties?.map_symbol_category_ndx,
          mapSymbolCategory: feature.properties?.map_symbol_category,
          mapSymShape: feature.properties?.map_sym_shape,
          mapSymFillColor: encodeURIComponent(
            feature.properties?.map_sym_fill_color
          ),
          mapSymStrokeColor: encodeURIComponent(
            feature.properties?.map_sym_stroke_color
          ),
          mapSymStokeWidth: feature.properties?.map_sym_stroke_width,
          mapSymPixelSize: feature.properties?.map_sym_pixel_size,
          mapSymLegendPixelSize: feature.properties?.map_sym_legend_pixel_size,
        };
        if (
          !acc.some(
            (item) =>
              parseInt(item.mapSymbolCategoryNdx) ===
              parseInt(symbolStyleData.mapSymbolCategoryNdx)
          )
        ) {
          acc.push(symbolStyleData);
        }
      });
    }
    return acc;
  }, []);
};
const useImagesCache = (sources, sourceIdsToCheck) => {
  const imagesCache = useMemo(() => {
    const symbolStyles = getSymbolStylesFrom(sources, sourceIdsToCheck);
    let cache = {};
    symbolStyles.forEach((image) => {
      const url = `${process.env.REACT_APP_ENDPOINT}/api/icon/icon?shape=${image.mapSymShape}&color=${image.mapSymFillColor}&strokeColor=${image.mapSymStrokeColor}&strokeWidth=${image.mapSymStokeWidth}&width=${image.mapSymPixelSize}&height=${image.mapSymPixelSize}`;
      const img = new Image();
      img.src = url;
      img.mapSymLegendPixelSize = image.mapSymLegendPixelSize;
      cache[image.mapSymbolCategoryNdx] = img;
    });
    return cache;
  }, [sources, sourceIdsToCheck]); // Recalculate cache only if these dependencies change

  return imagesCache;
};

export default useImagesCache;
