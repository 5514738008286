import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { isNullOrUndef } from "chart.js/helpers";
import { Pagination } from "@material-ui/lab";
import { titleize } from "inflected";
import { dateFormatter, formatBooleanTrueFalse } from "../../../utils";
import parse from "html-react-parser";
import { isWidthDown, Typography, withWidth } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { truncate } from "lodash";

const PopupWrap = styled.div`
  height: ${({ height }) => height};
  overflow-y: scroll;
  width: ${({ width }) => width};
  max-width: 100%;
`;

const PopupTable = styled.table`
  border-radius: 5px;
  border-collapse: collapse;
  border: 1px solid #ccc;
  width: 100%;
`;

const PopupRow = styled.tr`
  border-radius: 5px;
  &:nth-child(even) {
    background-color: #eee;
  }
`;

const PopupCell = styled.td`
  padding: 3px 6px;
  margin: 0;
`;

const PopupUl = styled.ul`
  list-style-type: none;
  margin: 0 !important;
  padding: 3px 0;
`;

const TruncatedTypographyTitle = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;
`;

const Popup = ({
  features,
  layers,
  height = "200px",
  maxWidth = "380px",
  size = "medium",
  width,
}) => {
  const getUniqueFeatures = (
    array,
    comparatorProperty1,
    comparatorProperty2
  ) => {
    const existingFeatureKeys = {};
    // Because features come from tiled vector data, feature geometries may be split
    // or duplicated across tile boundaries and, as a result, features may appear
    // multiple times in query results.
    //concat two ids to make a unique id
    return array.filter((el) => {
      if (
        existingFeatureKeys[el[comparatorProperty1] + el[comparatorProperty2]]
      ) {
        return false;
      } else {
        existingFeatureKeys[
          el[comparatorProperty1] + el[comparatorProperty2]
        ] = true;
        return true;
      }
    });
  };

  const uniqueFeatures = getUniqueFeatures(features, "id", "id");

  const [page, setPage] = useState(1);
  const [feature, setFeature] = useState(uniqueFeatures?.[0]);
  const [excludeFields, setExcludeFields] = useState([]);

  const handlePageChange = (e, p) => {
    setPage(p);
  };

  useEffect(() => {
    if (uniqueFeatures?.length) {
      setFeature(uniqueFeatures[page - 1]);
    }
  }, [uniqueFeatures, page]);

  useEffect(() => {
    const excludedFields = layers?.find(
      (layer) => layer?.id === feature?.layer?.id
    )?.lreProperties?.popup?.excludeFields;
    setExcludeFields(excludedFields || []);
  }, [feature, layers]);

  let popupData;
  if (feature?.layer?.id === "united-locations-circle") {
    popupData = [
      [
        "Facility Info",
        <Button
          component="a"
          href={`/facility-pages/${feature?.properties.facility_ndx}`}
          variant="contained"
          color="secondary"
          mt={4}
          target="_blank"
          rel="noopener noreferrer"
          size="small"
        >
          View Facility Page
        </Button>,
      ],
      ["Status", feature?.properties.reporting_status_desc],
      ["Last Report", feature?.properties.last_report],
      ...(feature?.properties?.most_recent_measurements
        ? [
            [
              "Most Recent Measurements",
              feature?.properties.most_recent_measurements
                .replace(/["{}[\]]/g, "")
                .replaceAll(",", ", "),
            ],
          ]
        : [["Most Recent Measurements", "N/A"]]),
      ...(feature?.properties?.facility_names
        ? [
            [
              "Facility Name",
              feature?.properties.facility_names
                .replace(/["{}[\]]/g, "")
                .replaceAll(",", ", "),
            ],
          ]
        : [["Facility Name", "N/A"]]),
      ...(feature?.properties?.facility_types
        ? [
            [
              "Facility Type",
              feature?.properties.facility_types
                .replace(/["{}[\]]/g, "")
                .replaceAll(",", ", "),
            ],
          ]
        : [["Facility Type", "N/A"]]),
      ["Location Type", feature?.properties.locationtype],
      ["County Name", feature?.properties.county_name],
    ];
  } else if (
    feature?.layer?.id === "dwr-diversion" ||
    feature?.layer?.id === "dwr-streamflow" ||
    feature?.layer?.id === "dwr-reservoir"
  ) {
    popupData = [
      ["Location ID", feature?.properties.location_id],
      ["Location Name", feature?.properties.location_display_label],
      ["Last Report", feature?.properties.last_report],
      [
        "View Data",
        `<a target="_blank" href='${feature?.properties.system_url}'>${feature?.properties.system_url}</a>`,
      ],
      ["Location Type", feature?.properties.locationtype],
      ["Waterbody", feature?.properties.waterbody_name],
      ["County", feature?.properties.county_name],
      ["Huc 12", feature?.properties.huc12_name],
      ["PLSS Location", feature?.properties.township_lndkey],
      ["Latitude", feature?.properties.latitude],
      ["Longitude", feature?.properties.longitude],
    ];
  } else if (feature?.layer?.id === "usds-spr-current-symbol") {
    popupData = [
      ["Location ID", feature?.properties.location_id],
      ["Location Name", feature?.properties.location_name],
      ["Source", feature?.properties.source],
      ["WDID", feature?.properties.wdid],
      ["Current Flow CFS", feature?.properties.current_flow_cfs],
      ["Stream Mile", feature?.properties.stream_mile],
      [
        "Most Recent Timestamp",
        dateFormatter(
          feature?.properties.most_recent_timestamp,
          "MM/DD/YYYY, h:mm a"
        ),
      ],
    ];
  } else if (feature?.layer?.id === "diversion-points-circle") {
    popupData = [
      [
        "More Info",
        feature?.properties.MoreInfo ? feature?.properties.MoreInfo : "N/A",
      ],
      [
        "Struct Type",
        feature?.properties.StructType ? feature?.properties.StructType : "N/A",
      ],
      ["WDID", feature?.properties.WDID ? feature?.properties.WDID : "N/A"],
      [
        "Water Src",
        feature?.properties.WaterSrc ? feature?.properties.WaterSrc : "N/A",
      ],
      [
        "Case Number",
        feature?.properties.AssocCaseN ? feature?.properties.AssocCaseN : "N/A",
      ],
      [
        "Permit",
        feature?.properties.AssocPermi ? feature?.properties.AssocPermi : "N/A",
      ],
      [
        "Use Notes",
        feature?.properties.CurrInUse ? feature?.properties.CurrInUse : "N/A",
      ],
      [
        "Decreed Rate Absolute",
        feature?.properties.DCRratAbs ? feature?.properties.DCRratAbs : "N/A",
      ],
      [
        "Decreed Rate Conditional",
        feature?.properties.DcrRatCond ? feature?.properties.DcrRatCond : "N/A",
      ],
      [
        "Decreed Volume Absolute",
        feature?.properties.DcrVolAbs ? feature?.properties.DcrVolAbs : "N/A",
      ],
      [
        "Decreed Volume Conditional",
        feature?.properties.DcrVolCond ? feature?.properties.DcrVolCond : "N/A",
      ],
      [
        "County",
        feature?.properties.County ? feature?.properties.County : "N/A",
      ],
      [
        "Latitude",
        feature?.properties.LatDecDeg ? feature?.properties.LatDecDeg : "N/A",
      ],
      [
        "Longitude",
        feature?.properties.LongDecDeg ? feature?.properties.LongDecDeg : "N/A",
      ],
      [
        "Loc Accuracy",
        feature?.properties.LocAccurac ? feature?.properties.LocAccurac : "N/A",
      ],
    ];
  } else if (feature?.layer?.id === "rivercalls") {
    popupData = [
      ["Title", `${feature?.properties.structure_name}`],
      ["WDID", `${feature?.properties.wdid}`],
      [
        "Last Report",
        `${
          feature?.properties.last_report
            ? feature?.properties.last_report
            : "N/A"
        }`,
      ],
      ["Water Source", `${feature?.properties.water_source}`],
      ["Call type", `${feature?.properties.call_type}`],
      [
        "Date Set",
        `${dateFormatter(feature?.properties.date_set, "MM/DD/yyyy")}`,
      ],
      ["Admin No.", `${feature?.properties.admin_no}`],
      [
        "Priority Date",
        `${dateFormatter(feature?.properties.appro_date, "MM/DD/yyyy")}`,
      ],
      ["Stream Mile", `${feature?.properties.stream_mile}`],
      ["Set Comments", `${feature?.properties.set_comments}`],
      [
        "Source Url",
        `<a target='_blank' href='${feature?.properties.source_url}'>Source</a>`,
      ],
    ];
  } else {
    popupData = excludeFields
      ? Object.entries(feature?.properties).reduce((acc, [key, value]) => {
          //MJB also removing entry if the value is an empty string, null, or undefined
          if (
            !excludeFields.includes(key) &&
            value !== "" &&
            !isNullOrUndef(value)
          ) {
            acc.push([key, value]);
          }
          return acc;
        }, [])
      : Object.entries(feature?.properties);
  }

  const [titleField, setTitleField] = useState("");

  useEffect(() => {
    const title = layers?.find((layer) => layer?.id === feature?.layer?.id)
      ?.lreProperties?.popup?.titleField;

    const layerName = layers?.find(
      (layer) => layer?.id === feature?.layer?.id
    )?.name;

    if (layerName === "DWR Water Districts") {
      setTitleField(
        (feature?.properties["district"] &&
          feature?.properties[title] &&
          layerName &&
          `Dist ${feature?.properties["district"]} - ${feature?.properties[title]} (Layer: ${layerName})`) ||
          titleize(feature?.layer?.source)
      );
    } else {
      setTitleField(
        (title &&
          feature?.properties[title] &&
          layerName &&
          `${feature?.properties[title]} (Layer: ${layerName})`) ||
          titleize(feature?.layer?.source)
      );
    }
  }, [feature, layers]);

  const getPopupRow = (key, value, layerId) => {
    let cellContent;
    let customRowStyle;
    const leftHandPopupColumnWidth =
      layerId === "diversion-points-circle" ? "42%" : "30%";
    if (["More Info", "MoreInfo"].includes(key)) {
      cellContent = (
        <div>
          <a target="_blank" href={value} rel="noreferrer">
            {truncate(value, { length: 35 })}
          </a>
        </div>
      );
    } else if (
      ["MostRecentMeasurements", "Most Recent Measurements"].includes(key)
    ) {
      cellContent = (
        <PopupUl>
          {value.split(",").map((item, i) => (
            <li key={i}>
              <strong>{parse(item)}</strong>
            </li>
          ))}
        </PopupUl>
      );
      customRowStyle = { backgroundColor: "#ffffc2" };
    } else if (
      layerId.includes("public-locations") &&
      ["LastReport", "Last Report"].includes(key)
    ) {
      cellContent = (
        <PopupUl>
          {value ? (
            value.split(",").map((item) => (
              <li key={item}>
                <strong>{parse(item.trim())}</strong>
              </li>
            ))
          ) : (
            <li>N/A</li>
          )}
        </PopupUl>
      );
      customRowStyle = { backgroundColor: "#ffffc2" };
    } else if (typeof value === "string" && value.startsWith("<a ")) {
      cellContent = (
        <PopupUl>
          {value.split(",").map((item) => (
            <li key={item}>{parse(item)}</li>
          ))}
        </PopupUl>
      );
    } else {
      cellContent = formatBooleanTrueFalse(value);
    }
    return (
      <PopupRow key={key} style={customRowStyle}>
        <PopupCell style={{ width: leftHandPopupColumnWidth }}>
          <strong>{key.toLowerCase() === "wdid" ? key : titleize(key)}</strong>
        </PopupCell>
        <PopupCell>{cellContent}</PopupCell>
      </PopupRow>
    );
  };

  if (!popupData) return null;
  return (
    <>
      <TruncatedTypographyTitle
        variant={isWidthDown("sm", width) ? "h4" : "h3"}
        style={{
          width: isWidthDown("sm", width) ? "205px" : maxWidth,
          marginTop: isWidthDown("sm", width) && "0",
          marginBottom: ".25rem",
          fontSize: "1rem",
        }}
      >
        {feature?.layer?.id === "united-locations-circle"
          ? feature?.properties.location_name
          : titleField}
      </TruncatedTypographyTitle>
      <PopupWrap
        height={isWidthDown("xs", width) ? "120px" : height}
        width={isWidthDown("sm", width) ? "205px" : maxWidth}
      >
        <PopupTable>
          <tbody>
            {popupData?.map(([key, value]) => {
              return getPopupRow(key, value, feature?.layer?.id);
            })}
          </tbody>
        </PopupTable>
      </PopupWrap>
      <Pagination
        style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
        count={uniqueFeatures.length}
        size={isWidthDown("sm", width) ? "small" : size}
        siblingCount={isWidthDown("sm", width) ? 0 : 1}
        page={page}
        variant="outlined"
        shape="rounded"
        color="primary"
        onChange={handlePageChange}
      />
    </>
  );
};

export default withWidth()(Popup);
