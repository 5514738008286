import { useCallback, useMemo, useState } from "react";
import {
  LINE_CHART_SELECT_ONLY_ONE_PARAMETER,
  TABLE_SELECT_ONLY_ONE_PARAMETER,
} from "../constants";
import { useFetch } from "./useFetch";

export const useParameters = ({ facilityNdx, associatedParameters }) => {
  const [parametersTable, setParametersTable] = useState([]);
  const [parametersGraph, setParametersGraph] = useState([]);

  const handleParametersTable = useCallback(
    ({ target: { value } }) => {
      if (value.length === 0 && parametersTable.length === 1) {
        return;
      }

      const updatedValues = parametersTable.includes(value[0])
        ? value.filter((val) => !parametersTable.includes(val))
        : value;

      setParametersTable(
        updatedValues.length > 0 ? updatedValues : parametersTable
      );
    },
    [parametersTable]
  );

  const handleParametersGraph = useCallback(
    ({ target: { value } }) => {
      if (value.length === 0 && parametersGraph.length === 1) {
        return;
      }

      const updatedValues = parametersGraph.includes(value[0])
        ? value.filter((val) => !parametersGraph.includes(val))
        : value;

      setParametersGraph(
        updatedValues.length > 0 ? updatedValues : parametersGraph
      );
    },
    [parametersGraph]
  );

  const { data: parametersData } = useFetch({
    endpoint: `list-parameters/${associatedParameters}`,
    options: {
      enabled: !!associatedParameters,
      onSuccess: async (data) => {
        if (TABLE_SELECT_ONLY_ONE_PARAMETER.includes(facilityNdx)) {
          setParametersTable([data[0].parameter_ndx]);
        } else {
          setParametersTable(data.map((d) => d.parameter_ndx));
        }
        if (LINE_CHART_SELECT_ONLY_ONE_PARAMETER.includes(facilityNdx)) {
          setParametersGraph([data[0].parameter_ndx]);
        }
      },
    },
  });

  const parametersOptions = useMemo(
    () =>
      parametersData?.map((parameter) => ({
        value: parameter.parameter_ndx,
        label: parameter.parameter_name,
      })) || [],
    [parametersData]
  );

  return {
    parametersTable,
    handleParametersTable,
    parametersGraph,
    handleParametersGraph,
    parametersOptions,
  };
};
