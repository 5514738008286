import React from "react";
import { useQuery } from "react-query";

import { Divider as MuiDivider, Typography } from "@material-ui/core";

import styled from "styled-components/macro";

import Table from "../../../components/Table";
import { spacing } from "@material-ui/system";
import Panel from "../../../components/panels/Panel";

import { Helmet } from "react-helmet-async";
import Loader from "../../../components/Loader";
import axios from "axios";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  max-height: calc(100% - 48px);
`;

const Divider = styled(MuiDivider)(spacing);
// const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

//388px
const BatteriesStatusTable = () => {
  const { data, isFetching, error } = useQuery(
    ["/api/display-batteries-last7days-stats"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/display-batteries-last7days-stats`
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  if (error) return "An error has occurred: " + error.message;

  const tabColumns = [
    {
      title: "Location Name",
      field: "location_display_label",
    },
    {
      title: "MIN 7-day",
      field: "min_7day",
    },
    {
      title: "MAX 7-day",
      field: "max_7day",
    },
  ];

  return (
    <>
      <Helmet title="Batteries Status Table" />
      <Typography variant="h3" gutterBottom display="inline">
        Batteries Status Table
      </Typography>
      <Divider my={6} />
      <Panel>
        <TableWrapper>
          {data ? (
            <Table
              label="Batteries Status Table"
              isLoading={isFetching}
              columns={tabColumns}
              data={data}
              height="calc(100vh - 64px - 48px - 106px - 48px - 64px - 156px)"
              pageSize={60}
              options={{ filtering: true }}
            />
          ) : (
            <Loader />
          )}
        </TableWrapper>
      </Panel>
    </>
  );
};

export default BatteriesStatusTable;
