import React, { useRef } from "react";
import { useQuery } from "react-query";
import {
  Accordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails,
  Divider as MuiDivider,
  Typography,
  Button,
  Box,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { dateFormatter, Legend } from "../../../utils";
import parse from "html-react-parser";
import MaterialTable from "material-table";
import { Link as MuiLink } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useAuth0 } from "@auth0/auth0-react";
import { LEGEND_ITEMS } from "./constants";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const Divider = styled(MuiDivider)(spacing);

const AccordionSummary = styled(MuiAccordionSummary)`
  && {
    min-height: 0;
  }
  .MuiAccordionSummary-content {
    margin: 0;
  }
`;

const Link = styled(MuiLink)`
  color: black;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

const formatCellContent = (text) => {
  if (typeof text === "string") {
    return text.replace(/\r\n|\r|\n/g, "<br />");
  } else return text;
};

const defaultCellStyle = {
  borderLeft: "1px solid rgba(0,0,0,0.12)",
  borderRight: "1px solid rgba(0,0,0,0.12)",
  borderTop: "1px solid rgba(0,0,0,0.12)",
  padding: "2px 5px 2px 5px",
};

const defaultHeaderStyle = {
  borderLeft: "1px solid rgba(0,0,0,0.12)",
  borderRight: "1px solid rgba(0,0,0,0.12)",
  borderTop: "1px solid rgba(0,0,0,0.12)",
  fontWeight: "600",
  padding: "2px",
  textAlign: "center",
};

const firstColumnHeaderStyle = {
  ...defaultHeaderStyle,
  textAlign: "left",
};

const combinedRowStyleCrosstab = (rowData) => {
  const alertColor =
    Object.values(rowData).find((cell) => cell?.raw?.highlight_color)?.raw
      ?.highlight_color ?? null;
  return {
    backgroundColor: alertColor ? alertColor : "inherit",
  };
};

const combinedRowStyle = (rowData) => {
  const alertColor = rowData?.highlight_color ?? null;
  return {
    backgroundColor: alertColor ? alertColor : "inherit",
  };
};

const reportConfigs = {
  storage: {
    endpoint: "/api/display-mobile/storage",
    rowIdentifier: "row_name",
    columnIdentifier: "column_name",
    valueField: "result_formatted",
    rowSortField: "row_sort",
    columnSortField: "column_sort",
    rowName: "Storage Site",
    tableLabel: "Storage Overview Report",
  },
  recharge: {
    endpoint: "/api/display-mobile/recharge",
    rowIdentifier: "row_name",
    columnIdentifier: "column_name",
    valueField: "result_formatted",
    rowSortField: "row_sort",
    columnSortField: "column_sort",
    rowName: "Recharge Site",
    tableLabel: "Recharge Overview Report",
  },
  augmentation: {
    endpoint: "/api/display-mobile/augmentation",
    rowIdentifier: "row_name",
    columnIdentifier: "column_name",
    valueField: "result_formatted",
    rowSortField: "row_sort",
    columnSortField: "column_sort",
    rowName: "Augmentation Site",
    tableLabel: "Aug Flows Overview Report",
  },
  wells: {
    endpoint: "/api/display-mobile/wells",
    rowIdentifier: "row_name",
    columnIdentifier: "column_name",
    valueField: "result_formatted",
    rowSortField: "row_sort",
    columnSortField: "column_sort",
    rowName: "Well",
    tableLabel: "Wells Overview Report",
  },
  lastreport: {
    endpoint: "/api/display-mobile/lastreport",
    tableLabel: "Last Reports (oldest by Facility)",
    columns: [
      {
        title: "Facility Name",
        field: "facility_name",
        render: (rowData) => (
          <Link
            component={RouterLink}
            to={`/facility-pages/${rowData.facility_ndx}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {rowData.facility_name}
          </Link>
        ),
        headerStyle: { ...firstColumnHeaderStyle },
      },
      { title: "Facility Type", field: "facility_type_desc" },
      {
        title: "Last Report Timestamp",
        field: "Oldest Timestamp at Facility",
        render: (rowData) => {
          return dateFormatter(
            rowData.last_report_timestamp,
            "MM/DD/YYYY, h:mm A"
          );
        },
      },
      {
        title: "Locations",
        field: "locations_labels",
        render: (rowData) =>
          rowData.locations_labels.map((loc) => (
            <li style={{ listStyleType: "none" }} key={loc}>
              {loc}
            </li>
          )),
      },
    ],
  },
  availableFlowCalcsForumlas: {
    endpoint: "/api/display-mobile/available-flow-calcs-formulas",
    tableLabel: "Avail Flow Calculation Formulas",
    columns: [
      {
        title: "Storage Site",
        field: "storage_site",
        headerStyle: { ...firstColumnHeaderStyle },
        render: (rowData) => (
          <Link
            component={RouterLink}
            to={`/facility-pages/${rowData.facility_ndx}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {rowData.storage_site}
          </Link>
        ),
      },
      {
        title: "Most Recent Value",
        field: "most_recent_value_cfs",
        cellStyle: {
          ...defaultCellStyle,
          textAlign: "right",
        },
      },
      { title: "Flow Calc Formula", field: "flow_calc_formula" },
    ],
  },
  availableFlowCalsNotes: {
    endpoint: "/api/display-mobile/available-flow-calcs-notes",
    tableLabel: "Avail Flow Calculation Notes",
    columns: [
      {
        title: "Storage Site",
        field: "storage_site",
        headerStyle: { ...firstColumnHeaderStyle },
        render: (rowData) => (
          <Link
            component={RouterLink}
            to={`/facility-pages/${rowData.facility_ndx}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {rowData.storage_site}
          </Link>
        ),
      },
      {
        title: "Flow Calc Notes",
        field: "flow_calc_notes",
        render: (rowData) => parse(formatCellContent(rowData.flow_calc_notes)),
      },
    ],
  },
  batteries: {
    endpoint: "/api/display-mobile/batteries",
    tableLabel: "Batteries",
    columns: [
      {
        title: "Location Name",
        field: "location_display_label",
        headerStyle: { ...firstColumnHeaderStyle },
      },
      {
        title: "MIN 7-day",
        field: "min_7day",
        cellStyle: {
          ...defaultCellStyle,
          textAlign: "right",
        },
      },
      {
        title: "MAX 7-day",
        field: "max_7day",
        cellStyle: {
          ...defaultCellStyle,
          textAlign: "right",
        },
      },
    ],
  },
};

const fetchData = async (endpoint, getAccessTokenSilently) => {
  const token = await getAccessTokenSilently();
  const headers = { Authorization: `Bearer ${token}` };
  const { data } = await axios.get(
    `${process.env.REACT_APP_ENDPOINT}${endpoint}`,
    { headers }
  );
  return data;
};

const useFetchData = (config) => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    data,
    isFetching: isLoading,
    error,
  } = useQuery(
    [config.endpoint],
    () => fetchData(config.endpoint, getAccessTokenSilently),
    { keepPreviousData: false, refetchOnWindowFocus: false }
  );
  return { data, isLoading, error };
};

const pivotData = (data, config) => {
  const {
    rowIdentifier,
    columnIdentifier,
    valueField,
    rowSortField,
    columnSortField,
  } = config;

  // Extract and sort unique columns
  const columns = Array.from(
    new Set(data.map((item) => item[columnIdentifier]))
  );

  if (columnSortField) {
    columns.sort((a, b) => {
      const columnA = data.find((d) => d[columnIdentifier] === a);
      const columnB = data.find((d) => d[columnIdentifier] === b);
      if (!columnA || !columnB) return 0;
      return columnA[columnSortField] - columnB[columnSortField];
    });
  }

  // Extract and sort unique rows
  const rows = Array.from(new Set(data.map((item) => item[rowIdentifier])));

  if (rowSortField) {
    rows.sort((a, b) => {
      const rowA = data.find((d) => d[rowIdentifier] === a);
      const rowB = data.find((d) => d[rowIdentifier] === b);
      if (!rowA || !rowB) return 0;
      return rowA[rowSortField] - rowB[rowSortField];
    });
  }

  // Pivot data
  const pivotedData = rows.map((row) => {
    const rowData = {
      [rowIdentifier]: {
        value: row,
        raw: data.find((d) => d[rowIdentifier] === row) || {},
      },
    };
    columns.forEach((column) => {
      const cell = data.find(
        (d) => d[rowIdentifier] === row && d[columnIdentifier] === column
      );
      rowData[column] = cell
        ? { value: cell[valueField], raw: cell }
        : { value: "", raw: {} };
    });
    return rowData;
  });

  return { columns: [rowIdentifier, ...columns], data: pivotedData };
};

const getTableColumns = (pivoted, config) => {
  return pivoted.columns.map((col, index) => ({
    title: col === config.rowIdentifier ? config.rowName : col,
    field: col,
    headerStyle: index === 0 ? firstColumnHeaderStyle : defaultHeaderStyle,
    cellStyle:
      col === config.rowIdentifier
        ? {
            ...defaultCellStyle,
            textAlign: "left",
          }
        : {
            ...defaultCellStyle,
            textAlign: "right",
          },
    render: (rowData) =>
      col === config.rowIdentifier ? (
        <Link
          component={RouterLink}
          to={`/facility-pages/${rowData[col]?.raw?.facility_ndx}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {rowData[col]?.value}
        </Link>
      ) : (
        rowData[col]?.value
      ),
  }));
};

const createAccordionTable = (
  config,
  data,
  isLoading,
  error,
  combinedRowStyleCrosstab
) => {
  if (error) return "An error has occurred: " + error.message;

  const pivoted = data?.length
    ? pivotData(data, config)
    : { columns: [], data: [] };
  const tableColumns = getTableColumns(pivoted, config);

  return (
    <Accordion title={config.tableLabel} defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">{config.tableLabel}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: "4px" }}>
        <MaterialTable
          style={{ width: "100%" }}
          columns={tableColumns}
          data={pivoted.data}
          isLoading={isLoading}
          options={{
            sorting: false,
            search: false,
            padding: "dense",
            paging: false,
            draggable: false,
            showTitle: false,
            toolbar: false,
            headerStyle: defaultHeaderStyle,
            rowStyle: combinedRowStyleCrosstab,
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

const ScrollButton = ({ onClick, label }) => (
  <Button
    onClick={onClick}
    variant="contained"
    color="secondary"
    startIcon={<ArrowDownwardIcon />}
  >
    {label}
  </Button>
);

const CrosstabMobileTracker = ({ reportType }) => {
  const config = reportConfigs[reportType];
  const { data, isLoading, error } = useFetchData(config);

  return createAccordionTable(
    config,
    data,
    isLoading,
    error,
    combinedRowStyleCrosstab
  );
};

const MobileTracker = ({ reportType }) => {
  const config = reportConfigs[reportType];
  const { data, isLoading, error } = useFetchData(config);

  if (error) return "An error has occurred: " + error.message;

  return (
    <Accordion title={config.tableLabel} defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">{config.tableLabel}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <MaterialTable
          style={{ width: "100%" }}
          columns={config.columns.map((col) => ({ ...col }))}
          data={data}
          isLoading={isLoading}
          options={{
            sorting: false,
            search: false,
            padding: "dense",
            paging: false,
            draggable: false,
            showTitle: false,
            toolbar: false,
            cellStyle: defaultCellStyle,
            headerStyle: defaultHeaderStyle,
            rowStyle: combinedRowStyle,
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

const MobileTrackers = () => {
  const lastReportRef = useRef(null);
  const batteryStatusRef = useRef(null);

  const scrollToRef = (ref) => {
    if (ref.current) {
      const offsetTop =
        ref.current.getBoundingClientRect().top + window.pageYOffset - 75;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  };

  return (
    <div style={{ maxWidth: "450px", margin: "0 auto", padding: "20px 8px" }}>
      <Helmet title="Mobile Tracker" />
      <Typography variant="h3" gutterBottom display="inline">
        Mobile Tracker
      </Typography>
      <Divider my={6} />
      <div style={{ marginBottom: "20px" }}>
        <Legend
          swatchWidth="20px"
          title="Highlighted rows are reporting recent data outages:"
          items={LEGEND_ITEMS}
        />
      </div>
      <Box display="flex" mb="16px" style={{ gap: "8px" }}>
        <ScrollButton
          label="Last Reports"
          onClick={() => scrollToRef(lastReportRef)}
        />
        <ScrollButton
          label="Battery Status"
          onClick={() => scrollToRef(batteryStatusRef)}
        />
      </Box>
      <Divider my={6} />
      <div>
        <CrosstabMobileTracker reportType="storage" />
        <CrosstabMobileTracker reportType="recharge" />
        <CrosstabMobileTracker reportType="augmentation" />
        <CrosstabMobileTracker reportType="wells" />
        <div ref={lastReportRef} />
        <MobileTracker reportType="lastreport" />

        <MobileTracker reportType="availableFlowCalcsForumlas" />
        <MobileTracker reportType="availableFlowCalsNotes" />
        <div ref={batteryStatusRef} />
        <MobileTracker reportType="batteries" />
      </div>
    </div>
  );
};

export default MobileTrackers;
