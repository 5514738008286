import React, { useMemo } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { getContrastYIQ, getLocationKeys, monthLookup } from "../../utils";

import {
  ChartTooltipContainer,
  SeriesTooltipContainer,
  TooltipTypography,
} from "../../styledComponents";

const processTooltipData = (data) => {
  return Object.entries(data)
    .filter(([key]) => key.startsWith("id_"))
    .map(([key, value]) => {
      const backgroundColor = data[`color_${key}`];
      const label = data[`label_${key}`];
      return {
        label: label || key,
        value: value.toFixed(2),
        units: data.units,
        backgroundColor,
        textColor: getContrastYIQ(backgroundColor),
      };
    });
};

const CustomBarTooltip = (indexBy) => {
  return React.memo(
    ({ _id, data }) => {
      const tooltipData = useMemo(() => processTooltipData(data), [data]);
      return (
        <ChartTooltipContainer backgroundcolor="black" color="white">
          <TooltipTypography>
            {indexBy === "collect_month"
              ? `${monthLookup[data[indexBy]].full} ${data["collect_year"]}`
              : data[indexBy]}{" "}
          </TooltipTypography>
          {tooltipData.map(
            ({ label, value, units, backgroundColor, textColor }, index) => (
              <SeriesTooltipContainer
                key={index}
                backgroundcolor={backgroundColor}
                color={textColor}
              >
                <TooltipTypography>{label}</TooltipTypography>
                <TooltipTypography>{`${value} ${units}`}</TooltipTypography>
              </SeriesTooltipContainer>
            )
          )}
        </ChartTooltipContainer>
      );
    },
    (prevProps, nextProps) => {
      return prevProps.id === nextProps.id;
    }
  );
};

const BarChart = ({ data, indexBy }) => {
  const locationKeys = useMemo(
    () => getLocationKeys(data, "id_").reverse(),
    [data]
  );

  // commenting this out but saving for future reference
  // const defs = useMemo(() => generateDefs(locationKeys), [locationKeys]);
  // const fills = useMemo(() => generateFills(locationKeys), [locationKeys]);
  return (
    <ResponsiveBar
      data={data}
      keys={locationKeys}
      indexBy={indexBy}
      groupMode="grouped"
      margin={{ top: 5, right: 0, bottom: 17, left: 55 }}
      padding={0.2}
      colors={({ id, data }) => data[`color_${id}`]}
      borderColor={{ from: "color", modifiers: [["darker", 2]] }}
      borderWidth={2}
      borderRadius={2}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 0,
        tickRotation: 0,
        legendPosition: "middle",
        legendOffset: 50,
      }}
      enableGridX={false}
      enableGridY={false}
      axisLeft={{
        tickValues: 4,
      }}
      enableLabel={false}
      animate={false}
      motionStiffness={90}
      motionDamping={15}
      tooltip={CustomBarTooltip(indexBy)}
    />
  );
};

export default BarChart;
