import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

const NonUserVisibilityFilter = ({ children }) => {
  const { user, isAuthenticated } = useAuth0();

  // If user is not authenticated, allow them to see it
  if (!isAuthenticated) return children;

  // Get user roles
  const roles = user?.[`${process.env.REACT_APP_AUDIENCE}/roles`] || [];

  // If the user is an Administrator or Developer, allow them
  if (roles.includes("Administrator") || roles.includes("Developer")) {
    return children;
  }

  // If the user has the "User" role, they should NOT see it
  if (roles.includes("User")) {
    return <React.Fragment />;
  }

  // Default: Allow visibility if no explicit role matches
  return children;
};

export default NonUserVisibilityFilter;
