import { useQuery } from "react-query";
import axios from "axios";

const useFacilityData = (endpoint, facilityNdx) => {
  return useQuery(
    [endpoint, facilityNdx],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}/${facilityNdx}`
        );
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    {
      enabled: !!facilityNdx,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useRecentDataSummary = (facilityNdx) => {
  const lastFewDataQuery = useFacilityData(
    "get-display-facility/lastfew",
    facilityNdx
  );
  const monthlyDataQuery = useFacilityData(
    "get-display-facility/monthly",
    facilityNdx
  );
  const ytdDataQuery = useFacilityData("get-display-facility/ytd", facilityNdx);
  const pctFullDataQuery = useFacilityData(
    "get-display-facility/pctfull",
    facilityNdx
  );

  const isLoading = [
    lastFewDataQuery,
    monthlyDataQuery,
    ytdDataQuery,
    pctFullDataQuery,
  ].some((query) => query.isFetching);
  const error = [
    lastFewDataQuery,
    monthlyDataQuery,
    ytdDataQuery,
    pctFullDataQuery,
  ].find((query) => query.error);

  return {
    lastFewData: lastFewDataQuery,
    monthlyData: monthlyDataQuery,
    ytdData: ytdDataQuery,
    pctFullData: pctFullDataQuery,
    error,
    isLoading,
  };
};
