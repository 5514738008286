import React from "react";
import styled from "styled-components/macro";
import { CircularProgress } from "@material-ui/core";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

function Loader({ color = "secondary" }) {
  const allowedColors = ["inherit", "primary", "secondary"];
  const isAllowedColor = allowedColors.includes(color);

  return (
    <Root>
      <CircularProgress
        m={2}
        color={isAllowedColor ? color : "inherit"} // Fallback to 'inherit' if a custom color is provided
        style={!isAllowedColor ? { color: color } : {}}
      />
    </Root>
  );
}

export default Loader;
