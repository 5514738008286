import MaterialTable from "material-table";
import styled from "styled-components/macro";
import { Card, CardHeader } from "@material-ui/core";
import { DataLoader, GridItem } from "../../styledComponents";

const StyledCard = styled(Card)`
  overflow: inherit;
  //width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const EXCLUDED_KEYS = [
  "location_ndx",
  "location_id",
  "unit_display_name",
  "tableData",
  "metric",
];

function generateColumns(data) {
  if (!data.length) {
    return [];
  }
  const columnKeys = Object.keys(data[0]).filter(
    (key) => !EXCLUDED_KEYS.includes(key)
  );

  const columns = [
    {
      title: "Metric",
      field: "metric",
      cellStyle: {
        borderLeft: "1px solid rgba(0,0,0,0.12)",
        borderRight: "1px solid rgba(0,0,0,0.12)",
        color: "rgb(49,98,175)",
        width: 170,
        minWidth: 170,
        fontWeight: "600",
      },
    },
  ];

  columnKeys.forEach((key) => {
    columns.push({
      title: key.replaceAll("location_id_", ""),
      field: key,
    });
  });

  return columns;
}

const CurrentValuesTableSection = ({ data = [], isLoading, customWrap }) => {
  return (
    <DataLoader isLoading={isLoading} isData={!!data?.length}>
      <GridItem
        customWrap={customWrap}
        md={12}
        lg={12}
        xl={12}
        xxl={"100%"}
        xxxl={"100%"}
      >
        <StyledCard title={"Current Totals"}>
          <CardHeader title={"Current Values by Well"} />
          <MaterialTable
            id="CurrentTotalsTable"
            columns={generateColumns(data)}
            data={data}
            options={{
              search: false,
              padding: "dense",
              paging: false,
              sorting: false,
              draggable: false,
              showTitle: false,
              toolbar: false,
              cellStyle: {
                borderLeft: "1px solid rgba(0,0,0,0.12)",
                borderRight: "1px solid rgba(0,0,0,0.12)",
              },
              headerStyle: {
                borderTop: "1px solid rgba(0,0,0,0.12)",
                borderLeft: "1px solid rgba(0,0,0,0.12)",
                borderRight: "1px solid rgba(0,0,0,0.12)",
                fontWeight: "600",
              },
            }}
          />
        </StyledCard>
      </GridItem>
    </DataLoader>
  );
};

export default CurrentValuesTableSection;
