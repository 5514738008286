import React, { useMemo } from "react";
import { useFacilitiesDownload } from "./useFacilitiesDownload";
import {
  Box,
  Button,
  Divider as MuiDivider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography as MuiTypography,
} from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DATE_FORMAT } from "../../../facilities/constants";
import DateFnsUtils from "@date-io/date-fns";
import Loader from "../../../../components/Loader";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { FILENAME } from "./constants";
import ButtonGroup from "@material-ui/core/ButtonGroup";

const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);

const DownloadButton = styled(Button)`
  height: 52px;
`;

const SelectButtonGroup = styled(ButtonGroup)`
  height: 53px;
  .MuiButton-root {
    width: 80px;
  }
  margin: 16px 0 8px 0;
`;

const SelectButton = styled(Button)`
  &:first-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const OptionsHeader = ({
  text,
  color = "secondary",
  variant = "subtitle1",
  ...rest
}) => (
  <Typography color={color} variant={variant} {...rest}>
    {text}
  </Typography>
);

const DateTimeFormControl = React.memo(({ id, label, value, onChange }) => (
  <FormControl margin="normal" fullWidth>
    <DateTimePicker
      format={DATE_FORMAT}
      inputVariant="outlined"
      autoOk
      id={id}
      label={label}
      value={value}
      onChange={onChange}
    />
  </FormControl>
));

const SelectControl = React.memo(
  ({
    label,
    id,
    value,
    onChange,
    options,
    isLoading,
    multiple = false,
    showSelectButtons = false,
  }) => {
    const handleSelectAll = () => {
      onChange({
        target: {
          value: options.map((option) => option.value),
        },
      });
    };

    const handleDeselectAll = () => {
      onChange({
        target: {
          value: [],
        },
      });
    };

    const selectStyles =
      showSelectButtons && multiple
        ? {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }
        : {};

    const isDisabled = options?.length === 0 || isLoading;

    return (
      <Box display="flex" flexDirection="row" width="100%" alignItems="start">
        <FormControl margin="normal" variant="outlined" fullWidth>
          <InputLabel id={`${id}-label`}>{label}</InputLabel>
          <Select
            labelId={`${id}-label`}
            id={id}
            multiple={multiple}
            value={value}
            onChange={onChange}
            label={label}
            style={selectStyles}
          >
            {isLoading ? (
              <MenuItem disabled>
                <Loader />
              </MenuItem>
            ) : (
              options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
        {showSelectButtons && multiple && (
          <SelectButtonGroup
            variant="outlined"
            color="primary"
            disabled={isDisabled}
          >
            <SelectButton variant="contained" onClick={handleSelectAll}>
              + All
            </SelectButton>
            <SelectButton onClick={handleDeselectAll}>- None</SelectButton>
          </SelectButtonGroup>
        )}
      </Box>
    );
  }
);

const FacilitiesDownload = () => {
  const {
    handleDownloadData,
    isLoading,
    error,
    inputs,
    options,
    updateOptions,
  } = useFacilitiesDownload();

  const facilityTypeOptions = useMemo(
    () =>
      inputs?.facilityTypes?.data?.map((facilityType) => ({
        value: facilityType.facility_type_ndx,
        label: facilityType.facility_type_desc,
      })) || [],
    [inputs?.facilityTypes?.data]
  );

  const facilityOptions = useMemo(
    () =>
      inputs?.facilities?.data
        ?.filter((facility) =>
          options.facilityTypes.includes(facility.facility_type_ndx)
        )
        .map((facility) => ({
          value: facility.facility_ndx,
          label: facility.facility,
        })) || [],
    [inputs?.facilities?.data, options.facilityTypes]
  );

  const measurementOptions = useMemo(
    () =>
      inputs?.parameterGroups?.data?.map((group) => ({
        value: group.parameter_group_ndx,
        label: group.parameter_group_name,
      })) || [],
    [inputs?.parameterGroups?.data]
  );

  const timeStepOptions = useMemo(
    () =>
      inputs?.endpoints?.data?.map((endpoint) => ({
        value: endpoint.value,
        label: endpoint.label,
      })) || [],
    [inputs?.endpoints?.data]
  );

  const isDownloadDisabled =
    options.facilities.length === 0 ||
    options.parameterGroups.length === 0 ||
    !options.startDate ||
    !options.endDate ||
    isLoading;

  if (error) {
    return <p>Error fetching data: {error.message}</p>;
  }

  return (
    <>
      <Helmet title="Query & Download - Facility Data" />
      <Typography variant="h3" gutterBottom display="inline">
        Query & Download - Facility Data
      </Typography>

      <Divider my={6} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <OptionsHeader text="Time Period" />
        </Grid>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={12} sm={4}>
            <DateTimeFormControl
              id="start-date"
              label="Start Date"
              value={options.startDate}
              onChange={(date) => updateOptions("startDate", date)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DateTimeFormControl
              id="end-date"
              label="End Date"
              value={options.endDate}
              onChange={(date) => updateOptions("endDate", date)}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <Grid item xs={12} sm={4}>
          <SelectControl
            label="Time Step"
            id="time-step"
            value={options.endpoint ?? ""}
            onChange={(e) => updateOptions("endpoint", e.target.value)}
            options={timeStepOptions}
            isLoading={!inputs?.endpoints?.data?.length}
          />
        </Grid>

        <Grid item xs={12}>
          <OptionsHeader text="Selection Criteria" />
        </Grid>
        <Grid item xs={12} lg={6}>
          <SelectControl
            label="Facility Types"
            id="facilityTypes"
            multiple
            value={options.facilityTypes ?? []}
            onChange={(e) => updateOptions("facilityTypes", e.target.value)}
            options={facilityTypeOptions}
            isLoading={!inputs?.facilityTypes?.data?.length}
            showSelectButtons
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <SelectControl
            label="Measurements"
            id="measurements"
            multiple
            value={options.parameterGroups ?? []}
            onChange={(e) => updateOptions("parameterGroups", e.target.value)}
            options={measurementOptions}
            isLoading={!inputs?.parameterGroups?.data?.length}
            showSelectButtons
          />
        </Grid>
        <Grid item xs={12}>
          <SelectControl
            label="Facilities"
            id="facilities"
            multiple
            value={options.facilities ?? []}
            onChange={(e) => updateOptions("facilities", e.target.value)}
            options={facilityOptions}
            isLoading={!inputs?.facilities?.data?.length}
            showSelectButtons
          />
        </Grid>

        <Grid item xs={12}>
          <DownloadButton
            fullWidth
            onClick={() => handleDownloadData({ filename: FILENAME })}
            type="submit"
            color="secondary"
            variant="contained"
            disabled={isDownloadDisabled}
            startIcon={isLoading ? <Loader color="inherit" /> : null}
          >
            {isLoading ? "Preparing..." : "Download"}
          </DownloadButton>
        </Grid>
      </Grid>
    </>
  );
};

export default FacilitiesDownload;
