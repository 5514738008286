import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useApp } from "../../../AppProvider";
import MaterialTable from "material-table";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CopyIcon from "@material-ui/icons/FileCopy";
import {
  Accordion,
  AccordionDetails,
  Divider as MuiDivider,
  Grid as MuiGrid,
  TextField,
  Button,
  AccordionSummary,
  Typography as MuiTypography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core";

import Panel from "../../../components/panels/Panel";
import Loader from "../../../components/Loader";
import { copyToClipboard, dateFormatter } from "../../../utils";
import useFetchData from "../../../hooks/useFetchData";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;

function ImportsConfiguration() {
  const { doToast } = useApp();
  const { getAccessTokenSilently } = useAuth0();

  const [selectedRow, setSelectedRow] = useState(null);

  const defaultRowValues = {
    import_ndx: null,
    location_ndx: null,
    parameter_ndx: null,
    unit_ndx: null,
    datasrc_ndx: null,
    enabled: true,
    last_retrieval: null,
    next_retrieval: null,
    file_path: null,
    file_name: null,
    file_fieldcount: 30,
    file_header_rowcount: 1,
    delimiter_ndx: 2,
    column_position_date: null,
    column_position_time: null,
    column_position_value: null,
    frequency: 30,
    run_now: true,
    retain_days: -1,
    history_days: 20,
    apply_daylightsavings_offset: 0,
    notes: null,
    source_site_id: null,
    source_site_metadata: null,
    interval_metadata: null,
    interval_period: null,
  };

  const [Locations] = useFetchData("list-locations", [], true);
  const locationsLookup = useMemo(() => {
    let converted = {};
    if (Locations?.length > 0) {
      Locations.forEach((item) => {
        converted[item.location_ndx] = item.location_name;
      });
    }
    return converted;
  }, [Locations]);

  const [Parameters] = useFetchData("list-parameters", [], true);
  const parametersLookup = useMemo(() => {
    let converted = {};
    if (Parameters?.length > 0) {
      Parameters.forEach((item) => {
        converted[item.parameter_ndx] = item.parameter_name;
      });
    }
    return converted;
  }, [Parameters]);

  const [Units] = useFetchData("list-units", [], true);

  const [DataSources] = useFetchData("list-sources", [], true);
  const dataSourcesLookup = useMemo(() => {
    let converted = {};
    if (DataSources?.length > 0) {
      DataSources.forEach((item) => {
        converted[item.datasrc_ndx] = item.data_source;
      });
    }
    return converted;
  }, [DataSources]);

  const [Delimiters] = useFetchData("list-import-delimiter", [], true);

  const daylightSavingsOffsetLookup = {
    0: "0 - no time offset applied",
    1: "1 - hour added to timestamps during daylight savings",
    2: "2 - hour subtracted from timestamps during standard time",
  };
  const handleUpdateSelectedRow = (name, value) =>
    setSelectedRow((prevState) => ({ ...prevState, [name]: value }));

  const { data, refetch } = useQuery(
    ["import-data-sources"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/import-data-sources`,
          { headers }
        );

        return data.map((item) => {
          return {
            ...item,
            frequency: moment.duration(item.frequency).asMinutes(),
            interval_period: moment.duration(item.interval_period).asMinutes(),
          };
        });
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const columns = [
    {
      title: "Location",
      field: "location_ndx",
      lookup: locationsLookup,
      cellStyle: {
        width: 500,
        minWidth: 500,
      },
    },
    {
      title: "Parameter",
      field: "parameter_ndx",
      lookup: parametersLookup,
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
    },
    {
      title: "Data Source",
      field: "datasrc_ndx",
      lookup: dataSourcesLookup,
      cellStyle: {
        width: 300,
        minWidth: 300,
      },
    },
    {
      title: "File Name",
      field: "file_name",
    },
    {
      title: "Import Frequency (minutes)",
      field: "frequency",
      render: (rowData) => {
        return rowData.frequency;
      },
    },
    {
      title: "Retain Days",
      field: "retain_days",
    },
    {
      title: "History Days",
      field: "history_days",
    },
    {
      title: "Apply Daylight Savings Offset",
      field: "apply_daylightsavings_offset",
      lookup: daylightSavingsOffsetLookup,
      cellStyle: {
        width: 500,
        minWidth: 500,
      },
    },
  ];

  const handleSubmit = () => {
    if (selectedRow.import_ndx) {
      handleUpdate().then();
    } else {
      handleAdd().then();
    }
  };

  const handleAdd = () => {
    return (async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/import-data-sources`,
          {
            ...selectedRow,
            interval_period: selectedRow.interval_period
              ? `${selectedRow.interval_period} minutes`
              : null,
            frequency: selectedRow.frequency
              ? `${selectedRow.frequency} minutes`
              : null,
          },
          { headers }
        );
        await refetch();
        doToast("success", "New entry was saved to the database");
        setSelectedRow(null);
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const handleUpdate = () => {
    return (async () => {
      try {
        if (selectedRow) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.put(
            `${process.env.REACT_APP_ENDPOINT}/api/import-data-sources/${selectedRow.import_ndx}`,
            {
              ...selectedRow,
              interval_period: selectedRow.interval_period
                ? `${selectedRow.interval_period} minutes`
                : null,
              frequency: selectedRow.frequency
                ? `${selectedRow.frequency} minutes`
                : null,
            },
            { headers }
          );
          await refetch();
          doToast("success", "New data was updated to the database");
          setSelectedRow(null);
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const handleDelete = (oldData) => {
    return (async () => {
      try {
        if (oldData) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.delete(
            `${process.env.REACT_APP_ENDPOINT}/api/import-data-sources/${oldData.import_ndx}`,
            { headers }
          );
          await refetch();
          doToast("success", "This entry was deleted from the database");
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const enabledOptions = [
    {
      value: true,
      option: "Yes",
    },
    {
      value: false,
      option: "No",
    },
  ];

  const daylightSavingsOffsetOptions = [
    {
      value: 0,
      option: "0 - no time offset applied",
    },
    {
      value: 1,
      option: "1 - hour added to timestamps during daylight savings",
    },
    {
      value: 2,
      option: "2 - hour subtracted from timestamps during standard time",
    },
  ];

  const disableSubmit =
    !selectedRow?.location_ndx ||
    !selectedRow?.parameter_ndx ||
    !selectedRow?.unit_ndx ||
    !selectedRow?.datasrc_ndx ||
    !selectedRow?.delimiter_ndx;

  const [showAll, setShowAll] = useState(false);
  const handleShowEnabled = () => {
    setShowAll((prevState) => {
      return !prevState;
    });
  };

  const filterData = (data) => {
    if (showAll) {
      return data;
    } else return data?.filter((d) => d.enabled);
  };

  return (
    <React.Fragment>
      <Helmet title="Imports Configuration" />
      <Typography variant="h3" gutterBottom display="inline">
        Imports Configuration
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
            >
              <Typography variant="h4" ml={2}>
                Imports Configuration
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <TableWrapper>
                  {data ? (
                    <MaterialTable
                      id="Imports Configuration"
                      title={`Imports Configuration ${dateFormatter(
                        new Date(),
                        "MM/DD/YYYY, h:mm A"
                      )}`}
                      columns={columns}
                      data={filterData(data)}
                      localization={{
                        toolbar: { searchPlaceholder: "Search Alerts" },
                      }}
                      components={{
                        Container: (props) => <div {...props} />,
                      }}
                      editable={{
                        onRowDelete: handleDelete,
                      }}
                      actions={[
                        {
                          icon: CopyIcon,
                          tooltip: "Copy Data",
                          isFreeAction: true,
                          onClick: () => {
                            try {
                              copyToClipboard(data, columns, () =>
                                doToast(
                                  "success",
                                  "Data was copied to your clipboard."
                                )
                              );
                            } catch (error) {
                              const message =
                                error?.message ?? "Something went wrong";
                              doToast("error", message);
                            }
                          },
                        },
                        () => ({
                          icon: "edit",
                          tooltip: "Edit",
                          onClick: (event, rowData) => {
                            setSelectedRow(rowData);
                          },
                        }),
                        {
                          icon: "add_box",
                          tooltip: "Add",
                          isFreeAction: true,
                          onClick: () => {
                            setSelectedRow(defaultRowValues);
                          },
                        },
                        {
                          icon: showAll ? "toggle_on" : "toggle_off",
                          iconProps: {
                            style: {
                              color: showAll ? "#4CAF50" : "currentcolor",
                            },
                          },
                          tooltip: showAll
                            ? "Toggle to show only enabled records"
                            : "Toggle to show all records",
                          isFreeAction: true,
                          onClick: handleShowEnabled,
                        },
                      ]}
                      options={{
                        filtering: true,
                        emptyRowsWhenPaging: false,
                        showTitle: false,
                        columnsButton: true,
                        exportButton: true,
                        exportAllData: true,
                        addRowPosition: "first",
                        pageSize: 10,
                        pageSizeOptions: [10, 30, 50, 100, 200],
                        padding: "dense",
                        searchFieldAlignment: "left",
                        maxBodyHeight:
                          "calc(100vh - 64px - 48px - 106px - 48px - 64px - 204px)",
                      }}
                    />
                  ) : (
                    <Loader />
                  )}
                </TableWrapper>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>

      {selectedRow && (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="table-content"
                id="table-header"
              >
                <Typography variant="h4" ml={2}>
                  {!selectedRow.import_ndx
                    ? "New Import Configuration"
                    : `Editing Import Configuration "${selectedRow.import_ndx}"`}
                </Typography>
              </AccordionSummary>
              <Panel>
                <AccordionDetails>
                  <Grid container spacing={5}>
                    <Typography variant="h5" ml={4} style={{ width: "100%" }}>
                      Import Controls
                      <Divider my={1} />
                    </Typography>

                    <Grid
                      item
                      xs={12}
                      sm={3}
                      style={{
                        position: "relative",
                      }}
                    >
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel id="enabled">Enabled</InputLabel>
                        <Select
                          labelId="enabled-label"
                          id="enabled"
                          label="Enabled"
                          value={
                            [false, true].includes(selectedRow.enabled)
                              ? selectedRow.enabled
                              : ""
                          }
                          onChange={(e) =>
                            handleUpdateSelectedRow("enabled", e.target.value)
                          }
                        >
                          {enabledOptions.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={3}
                      style={{
                        position: "relative",
                      }}
                    >
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel id="run-now">Run Now</InputLabel>
                        <Select
                          labelId="run-now-label"
                          id="run-now"
                          label="Run Now"
                          value={
                            [false, true].includes(selectedRow.run_now)
                              ? selectedRow.run_now
                              : ""
                          }
                          onChange={(e) =>
                            handleUpdateSelectedRow("run_now", e.target.value)
                          }
                        >
                          {enabledOptions.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        type="number"
                        variant="outlined"
                        label="Import Frequency (minutes)"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow("frequency", +e.target.value)
                        }
                        value={
                          selectedRow.frequency || selectedRow.frequency === 0
                            ? selectedRow.frequency
                            : ""
                        }
                        InputProps={{
                          inputProps: {
                            min: 0,
                            onKeyPress: (e) => {
                              if (["e", "."].includes(e.key)) {
                                e.preventDefault();
                              }
                            },
                          },
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        type="number"
                        variant="outlined"
                        label="Retain Days"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow("retain_days", e.target.value)
                        }
                        value={selectedRow.retain_days ?? ""}
                        InputProps={{
                          inputProps: {
                            onKeyPress: (e) => {
                              if (["e", "."].includes(e.key)) {
                                e.preventDefault();
                              }
                            },
                          },
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        type="number"
                        variant="outlined"
                        label="History Days"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow(
                            "history_days",
                            +e.target.value
                          )
                        }
                        value={selectedRow.history_days ?? ""}
                        InputProps={{
                          inputProps: {
                            min: 0,
                            onKeyPress: (e) => {
                              if (["e", "."].includes(e.key)) {
                                e.preventDefault();
                              }
                            },
                          },
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        position: "relative",
                      }}
                    >
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel id="enabled">
                          Apply Daylight Savings Offset
                        </InputLabel>
                        <Select
                          labelId="apply-daylightsavings-offset"
                          id="apply-daylightsavings-offset"
                          label="Apply Daylight Savings Offset"
                          value={
                            [0, 1, 2].includes(
                              selectedRow.apply_daylightsavings_offset
                            )
                              ? selectedRow.apply_daylightsavings_offset
                              : ""
                          }
                          onChange={(e) =>
                            handleUpdateSelectedRow(
                              "apply_daylightsavings_offset",
                              e.target.value
                            )
                          }
                        >
                          {daylightSavingsOffsetOptions.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Typography
                      variant="h5"
                      mt={3}
                      ml={4}
                      style={{ width: "100%" }}
                    >
                      Data Source Information
                      <Divider my={1} />
                    </Typography>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%" }}
                        error={!selectedRow.location_ndx}
                        required
                      >
                        <InputLabel id="send-to">Location</InputLabel>
                        <Select
                          labelId="location-label"
                          id="location"
                          label="Location"
                          name="location_ndx"
                          value={selectedRow.location_ndx || ""}
                          onChange={(e) => {
                            handleUpdateSelectedRow(
                              "location_ndx",
                              e.target.value
                            );
                          }}
                        >
                          {Locations.map((option) => (
                            <MenuItem
                              key={option.location_ndx}
                              value={option.location_ndx}
                            >
                              {option.location_name}
                            </MenuItem>
                          ))}
                        </Select>
                        {!selectedRow.location_ndx && (
                          <FormHelperText error>*Required field</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%" }}
                        error={!selectedRow.parameter_ndx}
                        required
                      >
                        <InputLabel id="send-to">Parameter</InputLabel>
                        <Select
                          labelId="parameter-label"
                          id="parameter"
                          label="Parameter"
                          name="parameter_ndx"
                          value={selectedRow.parameter_ndx || ""}
                          onChange={(e) => {
                            handleUpdateSelectedRow(
                              "parameter_ndx",
                              e.target.value
                            );
                          }}
                        >
                          {Parameters.map((option) => (
                            <MenuItem
                              key={option.parameter_ndx}
                              value={option.parameter_ndx}
                            >
                              {option.parameter_name}
                            </MenuItem>
                          ))}
                        </Select>
                        {!selectedRow.parameter_ndx && (
                          <FormHelperText error>*Required field</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%" }}
                        error={!selectedRow.datasrc_ndx}
                        required
                      >
                        <InputLabel id="send-to">Data Source</InputLabel>
                        <Select
                          labelId="data-source-label"
                          id="data-source"
                          label="Data Source"
                          name="datasrc_ndx"
                          value={selectedRow.datasrc_ndx || ""}
                          onChange={(e) => {
                            handleUpdateSelectedRow(
                              "datasrc_ndx",
                              e.target.value
                            );
                          }}
                        >
                          {DataSources.map((option) => (
                            <MenuItem
                              key={option.datasrc_ndx}
                              value={option.datasrc_ndx}
                            >
                              {option.data_source}
                            </MenuItem>
                          ))}
                        </Select>
                        {!selectedRow.datasrc_ndx && (
                          <FormHelperText error>*Required field</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        label="Source Site ID"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow(
                            "source_site_id",
                            e.target.value
                          )
                        }
                        value={selectedRow.source_site_id || ""}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        label="Source Site Metadata"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow(
                            "source_site_metadata",
                            e.target.value
                          )
                        }
                        value={selectedRow.source_site_metadata || ""}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        type="number"
                        variant="outlined"
                        label="Incoming Data Inverval (minutes)"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow(
                            "interval_period",
                            +e.target.value
                          )
                        }
                        value={
                          selectedRow.interval_period ||
                          selectedRow.interval_period === 0
                            ? selectedRow.interval_period
                            : ""
                        }
                        InputProps={{
                          inputProps: {
                            min: 0,
                            onKeyPress: (e) => {
                              if (["e", "."].includes(e.key)) {
                                e.preventDefault();
                              }
                            },
                          },
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        label="Interval Metadata"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow(
                            "interval_metadata",
                            e.target.value
                          )
                        }
                        value={selectedRow.interval_metadata || ""}
                      />
                    </Grid>

                    <Typography
                      variant="h5"
                      mt={3}
                      ml={4}
                      style={{ width: "100%" }}
                    >
                      Import File Details
                      <Divider my={1} />
                    </Typography>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        label="File Name"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow("file_name", e.target.value)
                        }
                        value={selectedRow.file_name || ""}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        label="File Path"
                        style={{ width: "100%" }}
                        value={selectedRow?.file_path || ""}
                        onChange={(e) =>
                          handleUpdateSelectedRow("file_path", e.target.value)
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%" }}
                        error={!selectedRow.unit_ndx}
                        required
                      >
                        <InputLabel id="send-to">Incoming Units</InputLabel>
                        <Select
                          labelId="unit-label"
                          id="unit"
                          label="Incoming Units"
                          name="unit_ndx"
                          value={selectedRow.unit_ndx || ""}
                          onChange={(e) => {
                            handleUpdateSelectedRow("unit_ndx", e.target.value);
                          }}
                        >
                          {Units.map((option) => (
                            <MenuItem
                              key={option.unit_ndx}
                              value={option.unit_ndx}
                            >
                              {option.unit_desc}
                            </MenuItem>
                          ))}
                        </Select>
                        {!selectedRow.unit_ndx && (
                          <FormHelperText error>*Required field</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%" }}
                        error={!selectedRow.delimiter_ndx}
                        required
                      >
                        <InputLabel id="send-to">
                          Text File Delimiter
                        </InputLabel>
                        <Select
                          labelId="delimiter-label"
                          id="delimiter"
                          label="Text File Delimiter"
                          name="delimiter_ndx"
                          value={selectedRow.delimiter_ndx || ""}
                          onChange={(e) => {
                            handleUpdateSelectedRow(
                              "delimiter_ndx",
                              e.target.value
                            );
                          }}
                        >
                          {Delimiters.map((option) => (
                            <MenuItem
                              key={option.delimiter_ndx}
                              value={option.delimiter_ndx}
                            >
                              {option.delimiter_desc}
                            </MenuItem>
                          ))}
                        </Select>
                        {!selectedRow.delimiter_ndx && (
                          <FormHelperText error>*Required field</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        type="number"
                        variant="outlined"
                        label="File Field Count"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow(
                            "file_fieldcount",
                            +e.target.value
                          )
                        }
                        value={selectedRow.file_fieldcount ?? ""}
                        InputProps={{
                          inputProps: {
                            min: 0,
                            onKeyPress: (e) => {
                              if (["e", "."].includes(e.key)) {
                                e.preventDefault();
                              }
                            },
                          },
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        type="number"
                        variant="outlined"
                        label="File Header Row Count"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow(
                            "file_header_rowcount",
                            +e.target.value
                          )
                        }
                        value={selectedRow.file_header_rowcount ?? ""}
                        InputProps={{
                          inputProps: {
                            min: 0,
                            onKeyPress: (e) => {
                              if (["e", "."].includes(e.key)) {
                                e.preventDefault();
                              }
                            },
                          },
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        type="number"
                        variant="outlined"
                        label="Column Position Date"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow(
                            "column_position_date",
                            +e.target.value
                          )
                        }
                        value={selectedRow.column_position_date ?? ""}
                        InputProps={{
                          inputProps: {
                            min: 0,
                            onKeyPress: (e) => {
                              if (["e", "."].includes(e.key)) {
                                e.preventDefault();
                              }
                            },
                          },
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        type="number"
                        variant="outlined"
                        label="Column Position Time"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow(
                            "column_position_time",
                            +e.target.value
                          )
                        }
                        value={selectedRow.column_position_time ?? ""}
                        InputProps={{
                          inputProps: {
                            min: 0,
                            onKeyPress: (e) => {
                              if (["e", "."].includes(e.key)) {
                                e.preventDefault();
                              }
                            },
                          },
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        type="number"
                        variant="outlined"
                        label="Column Position Value"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow(
                            "column_position_value",
                            +e.target.value
                          )
                        }
                        value={selectedRow.column_position_value ?? ""}
                        InputProps={{
                          inputProps: {
                            min: 0,
                            onKeyPress: (e) => {
                              if (["e", "."].includes(e.key)) {
                                e.preventDefault();
                              }
                            },
                          },
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        label="Notes"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          handleUpdateSelectedRow("notes", e.target.value)
                        }
                        value={selectedRow.notes || ""}
                      />
                    </Grid>

                    <Typography
                      variant="h5"
                      mt={3}
                      ml={4}
                      style={{ width: "100%" }}
                    >
                      Processing Status
                      <Divider my={1} />
                    </Typography>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <FormControl style={{ width: "100%" }}>
                          <KeyboardDateTimePicker
                            format="LLLL do, yyyy, h:mm a"
                            inputVariant="outlined"
                            autoOk
                            id="last-retrieval"
                            label="Last Retrieval"
                            value={selectedRow?.last_retrieval}
                            onChange={(date) => {
                              handleUpdateSelectedRow("last_retrieval", date);
                            }}
                            InputAdornmentProps={{
                              "aria-label": "change last retrieval date",
                            }}
                          />
                        </FormControl>
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        position: "relative",
                      }}
                    >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <FormControl style={{ width: "100%" }}>
                          <KeyboardDateTimePicker
                            format="LLLL do, yyyy, h:mm a"
                            inputVariant="outlined"
                            autoOk
                            id="next-retrieval"
                            label="Next Retrieval"
                            value={selectedRow?.next_retrieval}
                            onChange={(date) => {
                              handleUpdateSelectedRow("next_retrieval", date);
                            }}
                            InputAdornmentProps={{
                              "aria-label": "change next retrieval date",
                            }}
                          />
                        </FormControl>
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={7}
                      style={{
                        position: "relative",
                      }}
                    />
                    <Grid
                      item
                      xs={12}
                      md={2}
                      style={{
                        position: "relative",
                      }}
                    >
                      <Button
                        style={{ width: "100%", height: "44px" }}
                        size="small"
                        color="secondary"
                        variant="contained"
                        onClick={() => setSelectedRow(null)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      style={{
                        position: "relative",
                      }}
                    >
                      <Button
                        style={{ width: "100%", height: "44px" }}
                        type="submit"
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={disableSubmit}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Panel>
            </Accordion>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

export default ImportsConfiguration;
