import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import styled, { ThemeProvider } from "styled-components/macro";
import { useQuery } from "react-query";
import ResetZoomControl from "./ResetZoomControl";
import ToggleBasemapControl from "./ToggleBasemapControl";
import debounce from "lodash.debounce";
import { lineColors } from "../../utils";
import ReactDOM from "react-dom";
import {
  jssPreset,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import createTheme from "../../theme";
import Popup from "../../pages/publicMap/popup";
import { create } from "jss";
import { useSelector } from "react-redux";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { DEFAULT_MAP_CENTER } from "../../pages/publicMap/constants";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const TimeseriesComparisonMap = ({
  selectedFacilityLocations,
  selectedLeftLocations,
  selectedRightLocations,
}) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const theme = useSelector((state) => state.themeReducer);
  const [mapIsLoaded, setMapIsLoaded] = useState(false);
  const [map, setMap] = useState();

  const popUpRef = useRef(
    new mapboxgl.Popup({
      maxWidth: "400px",
      offset: 15,
      focusAfterOpen: false,
    })
  );
  const mapContainer = useRef(null); // create a reference to the map container

  const DUMMY_BASEMAP_LAYERS = [
    { url: "satellite-streets-v11", icon: "satellite_alt" },
    { url: "streets-v11", icon: "commute" },
    { url: "outdoors-v11", icon: "park" },
  ];

  const locationsLayer = {
    id: "united-locations-circle",
    type: "circle",
    source: "locations",
    paint: {
      "circle-stroke-width": [
        "case",
        ["in", ["get", "location_ndx"], ["literal", selectedLeftLocations]],
        5,
        ["in", ["get", "location_ndx"], ["literal", selectedRightLocations]],
        5,
        1,
      ],
      "circle-stroke-color": [
        "case",
        ["in", ["get", "location_ndx"], ["literal", selectedLeftLocations]],
        lineColors.yellow,
        ["in", ["get", "location_ndx"], ["literal", selectedRightLocations]],
        lineColors.red,
        "black",
      ],
      "circle-color": ["get", "map_sym_fill_color"],
      "circle-radius": 8,
    },
    lreProperties: {
      popup: {
        titleField: "location_name",
        excludeFields: ["index", "description"],
      },
    },
  };

  const locationsLabelsLayer = {
    id: "united-locations-labels",
    type: "symbol",
    source: "locations",
    minzoom: 7,
    layout: {
      "text-field": [
        "format",
        ["get", "location_name"],
        {}, // Use default formatting
      ],
      "text-size": 15,
      "text-offset": [0, -2],
      "text-font": ["literal", ["Roboto Regular", "Arial Unicode MS Regular"]],
      visibility: "visible",
    },
    paint: {
      "text-color": "#0000CC",
      "text-halo-color": "rgba(255, 255, 255, 0.9)",
      "text-halo-width": 2,
      "text-halo-blur": 0.1,
    },
  };

  const { data, isLoading, error } = useQuery(
    ["united", user],
    async () => {
      if (!user?.sub) return;
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/public-map/united`,
          { headers }
        );

        return data.data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/" + DUMMY_BASEMAP_LAYERS[0].url,
      center: DEFAULT_MAP_CENTER,
      zoom: 8.4,
    });

    map.addControl(new mapboxgl.NavigationControl(), "top-left");
    map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
      }),
      "top-left"
    );
    map.addControl(new mapboxgl.FullscreenControl());
    // Add locate control to the map.
    map.addControl(new ResetZoomControl(), "top-left");

    DUMMY_BASEMAP_LAYERS.forEach((layer) => {
      return map.addControl(new ToggleBasemapControl(layer.url, layer.icon));
    });

    map.on("load", () => {
      setMapIsLoaded(true);
      setMap(map);
    });
  }, []); // eslint-disable-line

  //resizes map when mapContainerRef dimensions changes (sidebar toggle)
  useEffect(() => {
    if (map) {
      const resizer = new ResizeObserver(debounce(() => map.resize(), 100));
      resizer.observe(mapContainer.current);
      return () => {
        resizer.disconnect();
      };
    }
  }, [map]);

  useEffect(() => {
    if (
      mapIsLoaded &&
      data?.features?.length > 0 &&
      typeof map != "undefined"
    ) {
      if (!map.getSource("locations")) {
        map.addSource("locations", {
          type: "geojson",
          data,
        });
        // Add a layer showing the places.
        map.addLayer(locationsLayer);
        map.addLayer(locationsLabelsLayer);

        map.on("click", "united-locations-circle", (e) => {
          map.fire("closeAllPopups");

          const features = map.queryRenderedFeatures(e.point);
          const myFeatures = features.filter(
            (feature) => feature.source === "locations"
          );
          const coordinates = [e.lngLat.lng, e.lngLat.lat];

          const popupNode = document.createElement("div");
          ReactDOM.render(
            //MJB adding style providers to the popup
            <StylesProvider jss={jss}>
              <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
                <ThemeProvider theme={createTheme(theme.currentTheme)}>
                  <Popup
                    layers={[locationsLayer]}
                    features={myFeatures}
                    // height="100%"
                    maxWidth="252px"
                    size="small"
                  />
                </ThemeProvider>
              </MuiThemeProvider>
            </StylesProvider>,
            popupNode
          );
          popUpRef.current
            .setLngLat(coordinates)
            .setDOMContent(popupNode)
            .addTo(map);
        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on("mouseenter", "united-locations-circle", () => {
          map.getCanvas().style.cursor = "pointer";
        });

        // Change it back to a pointer when it leaves.
        map.on("mouseleave", "united-locations-circle", () => {
          map.getCanvas().style.cursor = "";
        });
      }
    }
  }, [isLoading, mapIsLoaded, map, data]); //eslint-disable-line

  //filters the table based on the selected radioValues filters
  useEffect(() => {
    if (map !== undefined && map.getLayer("united-locations-circle")) {
      map.setFilter("united-locations-circle", [
        "in",
        ["get", "location_ndx"],
        ["literal", selectedFacilityLocations],
      ]);
      map.setFilter("united-locations-labels", [
        "in",
        ["get", "location_ndx"],
        ["literal", selectedFacilityLocations],
      ]);
    }
  }, [selectedFacilityLocations]); // eslint-disable-line

  useEffect(() => {
    if (map !== undefined && map.getLayer("united-locations-circle")) {
      map.setPaintProperty("united-locations-circle", "circle-stroke-width", [
        "case",
        ["in", ["get", "location_ndx"], ["literal", selectedLeftLocations]],
        5,
        ["in", ["get", "location_ndx"], ["literal", selectedRightLocations]],
        5,
        1,
      ]);
      map.setPaintProperty("united-locations-circle", "circle-stroke-color", [
        "case",
        [
          "all",
          ["in", ["get", "location_ndx"], ["literal", selectedLeftLocations]],
          ["in", ["get", "location_ndx"], ["literal", selectedRightLocations]],
        ],
        lineColors.orange,
        ["in", ["get", "location_ndx"], ["literal", selectedLeftLocations]],
        lineColors.yellow,
        ["in", ["get", "location_ndx"], ["literal", selectedRightLocations]],
        lineColors.red,
        "black",
      ]);
    }
  }, [selectedLeftLocations, selectedRightLocations]); //eslint-disable-line

  if (error) return "An error has occurred: " + error.message;

  return (
    <Root>
      <MapContainer ref={mapContainer} />
    </Root>
  );
};

export default TimeseriesComparisonMap;
