import { useQuery } from "react-query";
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";

const fetchRiverPhoto = async (cameraUrl, maskFileName, cancelToken) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_ENDPOINT}/api/river-photos`,
      { cameraUrl, maskFileName },
      {
        responseType: "blob",
        cancelToken,
      }
    );
    return URL.createObjectURL(data);
  } catch (error) {
    if (!axios.isCancel(error)) {
      console.error("Fetch error:", error);
    }
    throw error;
  }
};

const useFetchRiverPhoto = (cameraUrl, maskFileName) => {
  const cancelTokenSourceRef = useRef(axios.CancelToken.source());
  const [isCameraOfflineError, setIsCameraOfflineError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchPhoto = useCallback(() => {
    return fetchRiverPhoto(
      cameraUrl,
      maskFileName,
      cancelTokenSourceRef.current.token
    );
  }, [cameraUrl, maskFileName]);

  const { data: imageUrl, isFetching } = useQuery(
    ["fetchImage", cameraUrl, maskFileName],
    fetchPhoto,
    {
      // enable next line to disable refetch on window focus
      // refetchOnWindowFocus: false,
      enabled: !!cameraUrl && !isCameraOfflineError,
      staleTime: 0,
      cacheTime: 0,
      retry: (failureCount, error) => {
        return ![503, 504].includes(error.response?.status) && failureCount < 2;
      },
      onSuccess: () => {
        setIsCameraOfflineError(false);
        setErrorMessage("");
      },
      onError: (error) => {
        if ([503, 504].includes(error.response?.status)) {
          setIsCameraOfflineError(true);
          setErrorMessage("No response received from the camera.");
        } else {
          setIsCameraOfflineError(false);
          setErrorMessage("Something did not work as expected.");
        }
      },
    }
  );

  // Cleanup function to cancel the fetch request
  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      cancelTokenSourceRef.current.cancel(
        "Component unmounted or cameraUrl changed"
      );
    };
  }, [cameraUrl]);

  // Cleanup function to revoke the object URL
  useEffect(() => {
    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [imageUrl]);

  return { imageUrl, isLoading: isFetching, errorMessage };
};

export default useFetchRiverPhoto;
