import { Typography } from "@material-ui/core";
import FilterControl from "../filters/filterControl";
import Filter from "../filters/filter";
import WellStylesControl from "../controls/wellStylesControl";
import React from "react";
import styled from "styled-components/macro";
import useLayerStyles from "../hooks/useLayerStyles";
import { useAuth0 } from "@auth0/auth0-react";
import { useHasRequiredRole } from "../../../utils";

const FiltersSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
  flex: 1 1 0;
`;

const FiltersBar = ({
  filterValues,
  handleFilterValues,
  handleSelectAll,
  handleSelectNone,
  updateLayerStyles,
  isMapLoaded,
}) => {
  const { activeStyle, handleActiveStyle, styleOptions } = useLayerStyles({
    onLayerStyleChange: updateLayerStyles,
    isMapLoaded,
  });

  const { user } = useAuth0();
  const hasRequiredRole = useHasRequiredRole(user);

  return (
    <>
      {hasRequiredRole && (
        <>
          <FiltersSection>
            <FiltersContainer>
              <FilterControl
                width="166px"
                appliedCount={filterValues?.reportingStatus?.value?.length}
                label={filterValues?.reportingStatus?.label}
              >
                <Filter
                  label={filterValues?.reportingStatus?.label}
                  name={filterValues?.reportingStatus?.name}
                  onChange={handleFilterValues}
                  onSelectAll={handleSelectAll}
                  onSelectNone={handleSelectNone}
                  options={filterValues?.reportingStatus?.options}
                  type={filterValues?.reportingStatus?.type}
                  value={filterValues?.reportingStatus?.value}
                />
              </FilterControl>
              <FilterControl
                width="152px"
                appliedCount={filterValues?.locationTypes?.value?.length}
                label={filterValues?.locationTypes?.label}
              >
                <Filter
                  label={filterValues?.locationTypes?.label}
                  name={filterValues?.locationTypes?.name}
                  onChange={handleFilterValues}
                  onSelectAll={handleSelectAll}
                  onSelectNone={handleSelectNone}
                  options={filterValues?.locationTypes?.options}
                  type={filterValues?.locationTypes?.type}
                  value={filterValues?.locationTypes?.value}
                />
              </FilterControl>
            </FiltersContainer>
          </FiltersSection>
          <FiltersSection>
            <FiltersContainer>
              <FilterControl
                width="204px"
                label={`Color by ${activeStyle.name}`}
              >
                <Typography variant="subtitle1" gutterBottom>
                  Color Data Points by
                </Typography>
                <WellStylesControl
                  label="Color Data Points by"
                  name="wellStyles"
                  onChange={handleActiveStyle}
                  options={styleOptions}
                  value={activeStyle.id}
                />
              </FilterControl>
            </FiltersContainer>
          </FiltersSection>
        </>
      )}
    </>
  );
};

export default FiltersBar;
