import MaterialTable from "material-table";
import styled from "styled-components/macro";
import { Card, CardHeader } from "@material-ui/core";
import { DataLoader, GridItem } from "../../styledComponents";

const StyledCard = styled(Card)`
  overflow: inherit;
  //width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const COLUMNS = [
  {
    field: "metric",
  },
  {
    field: "value",
    cellStyle: {
      color: "rgb(49,98,175)",
      fontWeight: "600",
      borderTop: "1px solid rgba(0,0,0,0.12)",
      borderLeft: "1px solid rgba(0,0,0,0.12)",
      borderRight: "1px solid rgba(0,0,0,0.12)",
    },
  },
];

const CurrentTotalsTableSection = ({ data = [], isLoading, customWrap }) => {
  return (
    <DataLoader isLoading={isLoading} isData={!!data?.length}>
      <GridItem customWrap={customWrap}>
        <StyledCard title={"Current Totals"}>
          <CardHeader title={"Current Totals"} />
          <MaterialTable
            id="CurrentTotalsTable"
            columns={COLUMNS}
            data={data}
            options={{
              search: false,
              padding: "dense",
              paging: false,
              sorting: false,
              draggable: false,
              showTitle: false,
              header: false,
              toolbar: false,
              cellStyle: {
                borderTop: "1px solid rgba(0,0,0,0.12)",
                borderLeft: "1px solid rgba(0,0,0,0.12)",
                borderRight: "1px solid rgba(0,0,0,0.12)",
              },
            }}
          />
        </StyledCard>
      </GridItem>
    </DataLoader>
  );
};

export default CurrentTotalsTableSection;
