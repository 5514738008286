import { useCallback, useMemo, useState } from "react";
import { LINE_CHART_SELECT_ONLY_ONE_LOCATION } from "../constants";
import { useFetch } from "./useFetch";

export const useLocations = ({ facilityNdx, associatedLocations }) => {
  const [locations, setLocations] = useState([]);

  const handleLocations = useCallback(
    ({ target: { value } }) => {
      if (value.length === 0 && locations.length === 1) {
        return;
      }

      const updatedValues = locations.includes(value[0])
        ? value.filter((val) => !locations.includes(val))
        : value;

      setLocations(updatedValues.length > 0 ? updatedValues : locations);
    },
    [locations]
  );

  const { data: locationsData } = useFetch({
    endpoint: `list-locations/${associatedLocations}`,
    options: {
      enabled: !!associatedLocations,
      onSuccess: async (data) => {
        if (LINE_CHART_SELECT_ONLY_ONE_LOCATION.includes(facilityNdx)) {
          setLocations([data[0].location_ndx]);
        } else {
          setLocations(data.map((d) => d.location_ndx));
        }
      },
    },
  });

  const locationsOptions = useMemo(
    () =>
      locationsData?.map((location) => ({
        value: location.location_ndx,
        label: location.location_name,
      })) || [],
    [locationsData]
  );

  return {
    locations,
    handleLocations,
    locationsOptions,
  };
};
