import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";
import { spacing } from "@material-ui/system";
import { ROUTES } from "../../constants";

// const Container = styled.div`
//   width: 100%;
//   height: calc(100vh - 64px - 48px - 106px - 48px - 64px);
//   position: relative;
// `;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const IgnitionDemo = () => {
  return (
    <>
      <Helmet title="Ignition Demo" />
      <Typography variant="h3" gutterBottom display="inline">
        Ignition Demo
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to={ROUTES.PUBLIC_MAP}>
          Dashboard
        </Link>
        <Typography>Ignition Demo</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Link
          href="https://unitedwaterscada.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/static/img/ignition-demo.png"
            alt="United Water SCADA"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Link>
      </Box>

      {/*<Container>*/}
      {/*  <iframe*/}
      {/*    src="https://unitedwaterscada.com/data/perspective/client/SCADA/"*/}
      {/*    width="100%"*/}
      {/*    height="100%"*/}
      {/*    frameBorder="0"*/}
      {/*    title="Ignition Demo"*/}
      {/*  />*/}
      {/*</Container>*/}
    </>
  );
};

export default IgnitionDemo;
