import React, { useCallback, useMemo } from "react";

import MonthlyBarChartSection from "./MonthlyBarChartSection";
import { useTransformedFacilityData } from "../../hooks/useTransformedFacilityData";
import AnnualBarChartSection from "./AnnualBarChartSection";
import {
  BorderBox,
  HeaderBox,
  ScrollableGridContainer,
} from "../../styledComponents";
import { Typography, Button, withWidth, isWidthDown } from "@material-ui/core";
import { useRecentDataSummaryEccv } from "../../hooks/useRecentDataSummaryEccv";
import CurrentTotalsTableSection from "./CurrentTotalsTableSection";
import CurrentValuesBarChartSection from "./CurrentValuesBarChartSection";
import CurrentValuesTableSection from "./CurrentValuesTableSection";

const RecentDataSummaryEccv = React.memo(({ facilityNdx, width }) => {
  const {
    currentTotalsData,
    currentValuesByWellGraphData,
    currentValuesByWellTableData,
    monthlyData,
    ytdData,
    error,
  } = useRecentDataSummaryEccv(facilityNdx);

  const { transformedMonthlyData, transformedYtdData } =
    useTransformedFacilityData({ monthlyData, ytdData });

  const [customWrap, setCustomWrap] = React.useState(false);
  const toggleCustomWrap = useCallback(
    () => setCustomWrap((prevState) => !prevState),
    []
  );

  const isMobileView = useMemo(() => isWidthDown("md", width), [width]);

  if (error) {
    return <p>Error fetching recent data summary: {error.message}</p>;
  }

  return (
    <BorderBox>
      <HeaderBox>
        <Typography variant="h5">Recent Data Summary</Typography>
        {!isMobileView && (
          <Button variant="outlined" color="primary" onClick={toggleCustomWrap}>
            {customWrap ? "Expand" : "Collapse"}
          </Button>
        )}
      </HeaderBox>
      <ScrollableGridContainer container spacing={3} customWrap={customWrap}>
        <CurrentValuesBarChartSection
          data={currentValuesByWellGraphData?.data}
          isLoading={currentValuesByWellGraphData.isFetching}
          customWrap={customWrap}
        />

        <CurrentValuesTableSection
          data={currentValuesByWellTableData?.data}
          isLoading={currentValuesByWellTableData.isFetching}
          customWrap={customWrap}
        />

        <CurrentTotalsTableSection
          data={currentTotalsData?.data}
          isLoading={currentTotalsData.isFetching}
          customWrap={customWrap}
        />

        <MonthlyBarChartSection
          customWrap={customWrap}
          data={transformedMonthlyData}
          isLoading={monthlyData.isFetching}
        />

        <AnnualBarChartSection
          customWrap={customWrap}
          data={transformedYtdData}
          isLoading={ytdData.isFetching}
        />
      </ScrollableGridContainer>
    </BorderBox>
  );
});

export default withWidth()(RecentDataSummaryEccv);
