import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useCallback, useState } from "react";

export const useFacilities = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [selectedFacility, setSelectedFacility] = useState(null);

  //useCallback is used to memoize the function so that it is not recreated on every render
  const handleSelectFacility = useCallback(
    (facility) => {
      setSelectedFacility(facility);
    },
    [setSelectedFacility]
  );

  const { data, isFetching, error } = useQuery(
    ["data-list-facilities"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/data-list-facilities`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    selectedFacility,
    setSelectedFacility,
    data,
    isLoading: isFetching,
    error,
    handleSelectFacility,
  };
};
