import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useStateIfMounted } from "use-state-if-mounted";
import useFetchData from "../../../../hooks/useFetchData";
import { DEFAULT_FACILITY_INFO_STATE } from "../FacilitiesSetup/FacilitiesConstants";
import { colorToHex } from "../utils";

export const useFacilityInfo = (selectedFacility) => {
  const { getAccessTokenSilently } = useAuth0();

  const [editorState, setEditorState] = useStateIfMounted(
    DEFAULT_FACILITY_INFO_STATE
  );
  const [isDirty, setIsDirty] = useStateIfMounted(false);

  const handleUpdateState = (name, value) => {
    if (!isDirty) setIsDirty(true);
    setEditorState((prevState) => {
      let newFilterValues = { ...prevState };
      newFilterValues[name] = value;
      return newFilterValues;
    });
  };

  const handleResetState = () => {
    setIsDirty(false);
    if (selectedFacility) {
      setEditorState(data);
    } else {
      setEditorState(DEFAULT_FACILITY_INFO_STATE);
    }
  };

  const { data, isFetching, error, refetch } = useQuery(
    ["data-list-facilites", selectedFacility],
    async () => {
      if (!selectedFacility) {
        setIsDirty(false);
        setEditorState(DEFAULT_FACILITY_INFO_STATE);
        return {};
      }
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/data-list-facilities/${selectedFacility}`,
          { headers }
        );
        setIsDirty(false);
        setEditorState({
          ...data,
          legend_color: colorToHex(data.legend_color),
        });
        return { ...data, legend_color: colorToHex(data.legend_color) };
      } catch (err) {
        console.error(err);
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );
  const [facilityTypesLookup] = useFetchData(
    "list-facility-types-united-only",
    [],
    true
  );
  return {
    data,
    isLoading: isFetching,
    error,
    refetch,
    isDirty,
    lookups: {
      facilityTypes: facilityTypesLookup,
    },
    handleUpdateState,
    handleResetState,
    state: editorState,
    setter: setEditorState,
  };
};
