import { useMemo } from "react";

// Custom React hook that generates table column configurations based on static fields defined in `config`
// and dynamic fields identified in `data` by a specified prefix. It returns a combined array of both static
// and dynamically generated column configurations, suitable for rendering tables with both fixed and variable data.
export const useDynamicCrosstabColumns = ({ data, config }) => {
  return useMemo(() => {
    const { staticFields, dynamicField } = config;

    const staticColumns = staticFields.map(({ title, field, ...rest }) => ({
      title,
      field,
      ...rest,
    }));

    let dynamicColumns = [];
    if (data && data.length > 0) {
      // Build mapping of column labels to column orders
      const columnLabelToOrder = {};
      data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (key.startsWith("column_order_")) {
            const columnLabel = key.replace("column_order_", "");
            const columnOrder = item[key];
            if (
              columnOrder !== undefined &&
              !columnLabelToOrder.hasOwnProperty(columnLabel)
            ) {
              columnLabelToOrder[columnLabel] = columnOrder;
            }
          }
        });
      });

      const dynamicFieldsSet = data.reduce((acc, item) => {
        Object.keys(item).forEach((key) => {
          if (key.startsWith(dynamicField.dynamicFieldPrefix)) {
            acc.add(key);
          }
        });
        return acc;
      }, new Set());

      dynamicColumns = Array.from(dynamicFieldsSet)
        .map((field) => {
          let renderFunction = null;

          // Extract the crosstab field value
          const crosstabFieldValue = field.replace(
            dynamicField.dynamicFieldPrefix,
            ""
          );

          // Get the column order from the mapping
          const columnOrder = columnLabelToOrder[crosstabFieldValue] || 0;

          // Check for special render functions
          for (const key in config.specialRenderPartialFields) {
            if (field.includes(key)) {
              renderFunction = config.specialRenderPartialFields[key](field);
              break;
            }
          }

          return {
            title: crosstabFieldValue,
            field,
            order: columnOrder,
            ...dynamicField.cellStyle,
            ...(renderFunction && { render: renderFunction }),
          };
        })
        .sort((a, b) => {
          // First sort by column_order, then alphabetically
          if (a.order !== b.order) {
            return a.order - b.order;
          } else {
            return a.title.localeCompare(b.title);
          }
        });
    }
    return [...staticColumns, ...dynamicColumns];
  }, [data, config]);
};
