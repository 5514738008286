import { useCallback, useState } from "react";
import { QUICKSET_OPTIONS } from "../constants";

export const useDaysRange = () => {
  const [daysRange, setDaysRange] = useState(
    QUICKSET_OPTIONS[QUICKSET_OPTIONS.length - 1]
  );

  const handleDaysRange = useCallback((option) => setDaysRange(option), []);

  return {
    daysRange,
    handleDaysRange,
  };
};
