import React from "react";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

import MaterialTable from "material-table";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import {
  Divider as MuiDivider,
  Grid as MuiGrid,
  Typography as MuiTypography,
  TextField,
  Select,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import Panel from "../../components/panels/Panel";
import { useApp } from "../../AppProvider";
import Loader from "../../components/Loader";

import { Autocomplete } from "@material-ui/lab";
import useFetchData from "../../hooks/useFetchData";

import Isemail from "isemail";
import MenuItem from "@material-ui/core/MenuItem";

const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;

function UsersHome() {
  const { doToast } = useApp();
  const { getAccessTokenSilently } = useAuth0();

  const [Facilities] = useFetchData("list-facilities-with-types");

  const { data: LandingPagesLookup } = useQuery(
    ["LandingPagesLookup"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/users-management/list-user-landing-pages`,
          { headers }
        );
        const manipulatedData = {};
        data.forEach(
          (item) =>
            (manipulatedData[item.landing_page_ndx] = item.landing_page_name)
        );
        return manipulatedData;
      } catch (err) {
        // Is this error because we cancelled it ourselves?
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
        } else {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: UserRolesLookup } = useQuery(
    ["UserRolesLookup"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/users-management/list-user-roles`,
          { headers }
        );
        const manipulatedData = {};
        data.forEach(
          (item) =>
            (manipulatedData[item.auth0_role_id] = item["auth0_role_name"])
        );
        return manipulatedData;
      } catch (err) {
        // Is this error because we cancelled it ourselves?
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
        } else {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data, isLoading, error, refetch } = useQuery(
    ["users", Facilities],
    async () => {
      if (!Facilities.length) return;
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/users-management/users`,
          { headers }
        );
        const newData = data.map((item) => {
          return {
            ...item,
            ...{
              associated_facilities: item?.associated_facilities?.map((i) =>
                Facilities.find((facility) => facility.facility_ndx === i)
              ),
            },
          };
        });
        return newData;
      } catch (err) {
        // Is this error because we cancelled it ourselves?
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
        } else {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  function validateEmail(emailInput) {
    if (!emailInput) return false;
    return Isemail.validate(emailInput);
  }

  const editTableColumns = [
    {
      title: "User Id",
      field: "auth0_user_id",
      editable: "never",
      hidden: true,
    },
    {
      title: "Full-name",
      field: "auth0_name",
      validate: (rowData) =>
        !rowData.auth0_name
          ? {
              isValid: false,
              helperText: "Requires Full-name",
            }
          : true,
    },
    {
      title: "Nickname",
      field: "auth0_nickname",
      validate: (rowData) =>
        !rowData.auth0_nickname
          ? {
              isValid: false,
              helperText: "Requires Nickname",
            }
          : true,
    },
    {
      title: "Assigned Role",
      field: "assigned_role",
      initialEditValue: "rol_ynyDeeuE7JfPdOcM",
      lookup: UserRolesLookup,
      editComponent: (props) => (
        <FormControl fullWidth>
          <Select
            value={props.value ?? ""}
            onChange={(e) => {
              const newRole = e.target.value;

              // If switching to "User", force landing page to "1" ("Map Explorer"), since "2" ("Mobile Dashboard") is not allowed for "User"
              if (newRole === "rol_ynyDeeuE7JfPdOcM") {
                props.rowData.assigned_landing_page = "1";
                props.onRowDataChange({
                  ...props.rowData,
                  assigned_landing_page: "1",
                });
              }
              // Call onChange for role update
              props.onChange(newRole);
            }}
          >
            {Object.entries(UserRolesLookup).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
    },

    {
      title: "Assigned Landing Page",
      field: "assigned_landing_page",
      initialEditValue: "1",
      lookup: LandingPagesLookup,
      validate: (rowData) =>
        !rowData.assigned_landing_page
          ? {
              isValid: false,
            }
          : true,
      render: (rowData) => {
        return LandingPagesLookup[rowData.assigned_landing_page] || "N/A";
      },
      editComponent: (props) => {
        const selectedRole = props.rowData.assigned_role;
        const isUserRole = selectedRole === "rol_ynyDeeuE7JfPdOcM";

        return (
          <FormControl fullWidth error={!props.value}>
            <Select
              value={props.value ?? ""}
              onChange={(e) => props.onChange(e.target.value)}
              displayEmpty
            >
              {Object.entries(LandingPagesLookup)
                .filter(([key]) => !isUserRole || key !== "2") // Remove "Mobile Dashboard (2)" if User role is selected
                .map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
            </Select>
            {!props.value && (
              <FormHelperText>Requires Landing Page</FormHelperText>
            )}
          </FormControl>
        );
      },
    },
    {
      title: "Email",
      field: "auth0_email",
      validate: (rowData) =>
        !validateEmail(rowData?.auth0_email)
          ? {
              isValid: false,
              helperText: "Requires Email",
            }
          : true,
    },
    {
      title: "Password",
      field: "auth0_password",
      editable: "onAdd",
      render: () => "**********",
      validate: (rowData) =>
        !rowData?.auth0_password
          ? {
              isValid: false,
              helperText: "Requires Password",
            }
          : rowData?.auth0_password?.length < 8
          ? {
              isValid: false,
              helperText: "Must be at least 8 characters",
            }
          : true,
    },
    {
      title: "Associated Facilities",
      field: "associated_facilities",
      initialEditValue: [],
      cellStyle: {
        minWidth: 1000,
        maxWidth: 1000,
      },
      render: (rowData) => {
        return rowData?.associated_facilities
          ? rowData?.associated_facilities
              ?.map((facility) => facility?.facility_name)
              .join(", ")
          : "None";
      },

      editComponent: (props) => {
        return (
          <Autocomplete
            // limitTags={3}
            size="small"
            multiple
            disableCloseOnSelect
            id="facilities"
            name="facilities"
            options={Facilities}
            getOptionLabel={(option) => option?.facility_name}
            getOptionSelected={(option, value) =>
              option.facility_name === value.facility_name
            }
            filterSelectedOptions
            value={props?.value ?? []}
            onChange={(event, newValue) => {
              props.onChange(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                color="primary"
                placeholder="Facilities"
                variant="standard"
              />
            )}
          />
        );
      },
    },
  ];

  const handleAdd = (newData) => {
    return (async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/users-management/users`,
          {
            email: newData.auth0_email,
            name: newData.auth0_name,
            nickname: newData.auth0_nickname,
            password: newData.auth0_password,
            role: newData.assigned_role,
            landingPageNdx: newData.assigned_landing_page,
            facilities: newData.associated_facilities.map(
              (facility) => facility.facility_ndx
            ),
          },
          { headers }
        );

        await refetch();
        doToast("success", "This user was successfully created");
      } catch (err) {
        doToast("error", "Something went wrong");
        // Is this error because we cancelled it ourselves?
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
        } else {
          console.error(err);
        }
      }
    })();
  };

  const handleDelete = (oldData) => {
    return (async () => {
      try {
        if (oldData) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.delete(
            `${process.env.REACT_APP_ENDPOINT}/api/users-management/users/${oldData.auth0_user_id}`,
            { headers }
          );
          await refetch();
          doToast("success", "This user was deleted from the database");
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const handleUpdate = (newData, oldData) => {
    return (async () => {
      try {
        if (oldData) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.put(
            `${process.env.REACT_APP_ENDPOINT}/api/users-management/users`,
            {
              id: newData.auth0_user_id,
              email: newData.auth0_email,
              name: newData.auth0_name,
              nickname: newData.auth0_nickname,
              role: newData.assigned_role,
              landingPageNdx: newData.assigned_landing_page,
              facilities: newData.associated_facilities.map(
                (facility) => facility.facility_ndx
              ),
            },
            { headers }
          );

          await refetch();
          doToast("success", "This user was updated in the database");
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  if (error) return "An error has occurred: " + error.message;

  return (
    <React.Fragment>
      <Helmet title="Users Management" />
      <Typography variant="h3" gutterBottom display="inline">
        Users Management
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Panel>
            <TableWrapper>
              {data ? (
                <MaterialTable
                  id="Users Management"
                  columns={editTableColumns}
                  data={data}
                  editable={{
                    onRowDelete: handleDelete,
                    onRowAdd: handleAdd,
                    onRowUpdate: handleUpdate,
                  }}
                  localization={{
                    toolbar: { searchPlaceholder: "Search Users" },
                  }}
                  components={{
                    Container: (props) => <div {...props} />,
                  }}
                  isLoading={isLoading}
                  options={{
                    emptyRowsWhenPaging: false,
                    addRowPosition: "first",
                    pageSize: 30,
                    pageSizeOptions: [5, 10, 30, 60],
                    searchFieldAlignment: "left",
                    showTitle: false,
                    maxBodyHeight: "600px",
                  }}
                />
              ) : (
                <Loader />
              )}
            </TableWrapper>
          </Panel>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default UsersHome;
