import React, { useMemo } from "react";
import { TableWrapper } from "../../styledComponents";
import Table from "../../components/Table";
import { useDynamicCrosstabColumns } from "../../hooks/useDynamicCrosstabColumns";
import { CROSSTAB_COLUMNS_CONFIG } from "../../constants";

const TableSection = React.memo(({ data, isLoading, selectedParameters }) => {
  const filteredData = useMemo(() => {
    return (
      data?.filter((item) =>
        selectedParameters?.includes(item.parameter_ndx)
      ) || []
    );
  }, [data, selectedParameters]);

  const columns = useDynamicCrosstabColumns({
    data: filteredData,
    config: CROSSTAB_COLUMNS_CONFIG,
  });

  return (
    <TableWrapper>
      <Table
        title="15-Minute Data"
        isLoading={isLoading}
        columns={columns}
        data={filteredData}
        options={{
          cellStyle: {
            borderLeft: "1px solid rgba(0,0,0,0.12)",
            borderRight: "1px solid rgba(0,0,0,0.12)",
          },
          headerStyle: {
            borderTop: "1px solid rgba(0,0,0,0.12)",
            borderLeft: "1px solid rgba(0,0,0,0.12)",
            borderRight: "1px solid rgba(0,0,0,0.12)",
            fontWeight: "600",
          },
        }}
      />
    </TableWrapper>
  );
});

export default TableSection;
