import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import styled from "styled-components/macro";
import { useQuery } from "react-query";
import debounce from "lodash.debounce";
import axios from "axios";
import ResetZoomControl from "../../../../components/map/ResetZoomControl";
import ToggleBasemapControl from "../../../../components/map/ToggleBasemapControl";
import { useAuth0 } from "@auth0/auth0-react";
import { STARTING_LOCATION } from "../../../../constants";
import { RulerControl } from "mapbox-gl-controls";
import {
  DUMMY_BASEMAP_LAYERS,
  UNITED_LOCATIONS_LABELS_LAYER,
  UNITED_LOCATIONS_LAYER,
} from "./constants";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

const FacilityMap = ({ lat, lon, zoom }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [mapIsLoaded, setMapIsLoaded] = useState(false);
  const [map, setMap] = useState(null);

  const mapContainer = useRef(null); // create a reference to the map container

  const { data, isLoading, error } = useQuery(
    ["united"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const {
          data: { data },
        } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/public-map/united`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/" + DUMMY_BASEMAP_LAYERS[0].url,
      center: lon && lat ? [lon, lat] : STARTING_LOCATION,
      zoom: zoom || 6,
    });

    map.addControl(new mapboxgl.NavigationControl(), "top-left");

    map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showUserHeading: true,
      }),
      "top-left"
    );

    map.addControl(new ResetZoomControl(), "top-left");

    map.addControl(new mapboxgl.FullscreenControl());

    DUMMY_BASEMAP_LAYERS.forEach((layer) => {
      return map.addControl(new ToggleBasemapControl(layer.url, layer.icon));
    });

    map.addControl(
      new RulerControl({
        units: "feet",
        labelFormat: (n) => `${n.toFixed(2)} ft`,
      }),
      "bottom-left"
    );

    map.addControl(
      new mapboxgl.ScaleControl({ unit: "imperial" }),
      "bottom-left"
    );

    map.on("load", () => {
      setMapIsLoaded(true);
      setMap(map);
    });
  }, []); // eslint-disable-line

  //resizes map when mapContainerRef dimensions changes (sidebar toggle)
  useEffect(() => {
    if (map) {
      const resizer = new ResizeObserver(debounce(() => map.resize(), 100));
      resizer.observe(mapContainer.current);
      return () => {
        resizer.disconnect();
      };
    }
  }, [map]);

  useEffect(() => {
    if (mapIsLoaded && data && !!Object.values(data)?.length && !!map) {
      if (!map.getSource("united-locations")) {
        map.addSource("united-locations", {
          type: "geojson",
          data: data,
        });
        // Add a layer showing the places.
        map.addLayer(UNITED_LOCATIONS_LAYER);
        map.addLayer(UNITED_LOCATIONS_LABELS_LAYER);
      }
    }
  }, [isLoading, mapIsLoaded, map, data]); //eslint-disable-line

  if (error) return "An error has occurred: " + error.message;

  return (
    <Root>
      <MapContainer ref={mapContainer} />
    </Root>
  );
};

export default FacilityMap;
