import React, { useState, memo } from "react";
import {
  Paper,
  Typography as MuiTypography,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CodeIcon from "@material-ui/icons/Code";
import styled from "styled-components/macro";
import Draggable from "react-draggable";
import { spacing } from "@material-ui/system";
import useMapDevTools from "../../hooks/useMapDevTools";
import { useApp } from "../../../../AppProvider";

const useClipboard = () => {
  const { doToast } = useApp();
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        doToast("success", `Copied to clipboard: ${text}`);
      })
      .catch((err) => {
        doToast("error", `Error copying text: ${err}`);
      });
  };
  return copyToClipboard;
};

const useDraggable = () => {
  const [isDragging, setIsDragging] = useState(false);
  const handleStartDrag = () => setIsDragging(true);
  const handleStopDrag = () => setIsDragging(false);

  return { isDragging, handleStartDrag, handleStopDrag };
};

const formatCoordinate = (coord) =>
  ![undefined, null].includes(coord) ? coord.toFixed(5) : "N/A";

const Typography = styled(MuiTypography)(spacing);

const BaseContainer = styled(Paper)`
  position: absolute;
  bottom: 92px;
  right: 88px;
  z-index: 1499;
`;

const RootContainer = styled(BaseContainer)`
  width: 175px;
  padding: 12px;
  cursor: ${({ $isDragging }) => ($isDragging ? "grabbing" : "grab")};
`;

const ToggleButtonContainer = styled(BaseContainer)`
  width: auto;
  background-color: #ffffff;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 4px;
  right: 4px;
`;

const Header = styled(Typography)`
  font-weight: bold;
  margin-bottom: 8px;
`;

const CopyableText = memo(({ value, tooltipTitle, onCopy, ...props }) => {
  const handleClick = () => {
    if (onCopy) {
      onCopy(value);
    }
  };

  return (
    <Tooltip title={tooltipTitle || (onCopy ? "Click to copy" : "")}>
      <Typography
        {...props}
        onClick={handleClick}
        style={{ cursor: onCopy ? "copy" : "inherit" }}
      />
    </Tooltip>
  );
});

const CoordinateGroup = memo(({ title, coordinates, onCopy }) => (
  <>
    <Typography variant="body1">
      <strong>{title}</strong>
    </Typography>
    {coordinates.map((coord, index) => (
      <CopyableText
        key={index}
        variant="body1"
        pl="16px"
        value={formatCoordinate(coord)}
        tooltipTitle={onCopy ? `Click to copy ${["lon", "lat"][index]}` : ""}
        onCopy={onCopy}
      >
        {`${["lon", "lat"][index]}: ${formatCoordinate(coord)}`}
      </CopyableText>
    ))}
  </>
));

const ToggleButton = memo(({ handleToggleVisibility }) => (
  <ToggleButtonContainer onClick={handleToggleVisibility}>
    <Tooltip title="Open Map Developer Tools">
      <IconButton size="small" color="default">
        <CodeIcon />
      </IconButton>
    </Tooltip>
  </ToggleButtonContainer>
));

const DraggableContainer = memo(
  ({ children, isDragging, handleStartDrag, handleStopDrag }) => {
    return (
      <Draggable onStart={handleStartDrag} onStop={handleStopDrag}>
        <RootContainer $isDragging={isDragging}>{children}</RootContainer>
      </Draggable>
    );
  }
);

const MapDevTools = ({ map }) => {
  const [isVisible, setIsVisible] = useState(true);

  const { zoomLevel, centerCoordinates, mouseCoordinates, mapBounds } =
    useMapDevTools(map, isVisible);
  const copyToClipboard = useClipboard();
  const { isDragging, handleStartDrag, handleStopDrag } = useDraggable();

  const handleToggleVisibility = () => setIsVisible(!isVisible);

  return (
    <>
      {isVisible ? (
        <DraggableContainer
          isDragging={isDragging}
          handleStartDrag={handleStartDrag}
          handleStopDrag={handleStopDrag}
        >
          <Header variant="body2">Map Dev Tools</Header>
          <CloseButton size="small" onClick={handleToggleVisibility}>
            <CloseIcon fontSize="small" />
          </CloseButton>
          <CopyableText
            value={zoomLevel.toFixed(1)}
            tooltipTitle="Click to copy zoom level"
            variant="body1"
            onCopy={copyToClipboard}
          >
            <strong>Zoom Level:</strong> {zoomLevel.toFixed(1)}
          </CopyableText>
          <CoordinateGroup
            title="Center Coords:"
            coordinates={centerCoordinates}
            onCopy={copyToClipboard}
          />
          <CoordinateGroup
            title="Mouse Coords:"
            coordinates={mouseCoordinates}
          />
          <CoordinateGroup
            title="SW Bounds:"
            coordinates={mapBounds[0]}
            onCopy={copyToClipboard}
          />
          <CoordinateGroup
            title="NE Bounds:"
            coordinates={mapBounds[1]}
            onCopy={copyToClipboard}
          />
        </DraggableContainer>
      ) : (
        <ToggleButton handleToggleVisibility={handleToggleVisibility} />
      )}
    </>
  );
};

export default memo(MapDevTools);
