import React from "react";
import LiquidFillGauge from "react-liquid-gauge";

const circleStyle = { fill: "#00008B" }; // Circle color
const waveStyle = { fill: "#0000FF" }; // Wave color
const textStyle = { fill: "#444", fontFamily: "Arial" };
const waveTextStyle = { fill: "#fff", fontFamily: "Arial" };

const calculateTextStyles = (
  radius,
  textSizeMultiplier,
  decimalMultiplier,
  percentMultiplier
) => ({
  valueStyle: { fontSize: radius * textSizeMultiplier },
  decimalStyle: { fontSize: radius * textSizeMultiplier * decimalMultiplier },
  percentStyle: { fontSize: radius * textSizeMultiplier * percentMultiplier },
});

const PercentFull = React.memo(({ pct_full }) => {
  const percentFull = pct_full ? pct_full * 100 : 0;

  return (
    <LiquidFillGauge
      width={125}
      height={125}
      value={percentFull}
      percent="%"
      textSize={1.25}
      textOffsetY={13}
      riseAnimation
      waveAnimation
      waveFrequency={1}
      waveAmplitude={2.5}
      textRenderer={(props) => {
        const [integral, decimal] = props.value.toFixed(1).split(".");
        const radius = Math.min(props.height / 2, props.width / 2);
        const { valueStyle, decimalStyle, percentStyle } = calculateTextStyles(
          radius,
          0.5,
          0.75,
          0.5
        );

        return (
          <>
            <tspan className="value" style={valueStyle}>
              {integral}.
            </tspan>
            <tspan style={decimalStyle}>{decimal}</tspan>
            <tspan style={percentStyle}>{props.percent}</tspan>
          </>
        );
      }}
      riseAnimationTime={1000}
      waveAnimationTime={1000}
      circleStyle={circleStyle}
      waveStyle={waveStyle}
      textStyle={textStyle}
      waveTextStyle={waveTextStyle}
    />
  );
});

export default PercentFull;
