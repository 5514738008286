import { useQuery } from "react-query";
import axios from "axios";

const useFacilityData = (endpoint, facilityNdx) => {
  return useQuery(
    [endpoint, facilityNdx],
    async () => {
      try {
        let url = `${process.env.REACT_APP_ENDPOINT}/api/${endpoint}`;

        if (facilityNdx) {
          url = `${url}/${facilityNdx}`;
        }
        const { data } = await axios.get(url);
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useRecentDataSummaryEccv = (facilityNdx) => {
  const currentTotalsQuery = useFacilityData(
    "get-display-facility/eccv-current-totals"
  );

  const currentValuesByWellGraphQuery = useFacilityData(
    "get-display-facility/eccv-current-values-by-well/graph"
  );

  const currentValuesByWellTableQuery = useFacilityData(
    "get-display-facility/eccv-current-values-by-well/table"
  );

  const monthlyDataQuery = useFacilityData(
    "get-display-facility/monthly",
    facilityNdx
  );
  const ytdDataQuery = useFacilityData("get-display-facility/ytd", facilityNdx);

  const isLoading = [
    currentTotalsQuery,
    currentValuesByWellGraphQuery,
    currentValuesByWellTableQuery,
    monthlyDataQuery,
    ytdDataQuery,
  ].some((query) => query.isFetching);
  const error = [
    currentTotalsQuery,
    currentValuesByWellGraphQuery,
    currentValuesByWellTableQuery,
    monthlyDataQuery,
    ytdDataQuery,
  ].find((query) => query.error);

  return {
    currentTotalsData: currentTotalsQuery,
    currentValuesByWellGraphData: currentValuesByWellGraphQuery,
    currentValuesByWellTableData: currentValuesByWellTableQuery,
    monthlyData: monthlyDataQuery,
    ytdData: ytdDataQuery,
    error,
    isLoading,
  };
};
