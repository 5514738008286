import { useMutation, useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useState } from "react";
import { useApp } from "../../../../AppProvider";
import { fetchData, triggerDownload } from "../utils";
import { DEFAULT_OPTIONS, USE_QUERY_OPTIONS } from "./constants";
import axios from "axios";

export const useFacilitiesDownload = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { doToast } = useApp();

  const fetchWithToken = async (endpoint) => {
    const token = await getAccessTokenSilently();
    return fetchData(`${process.env.REACT_APP_ENDPOINT}${endpoint}`, token);
  };

  const facilityTypesQuery = useQuery(
    ["facilityTypes"],
    () =>
      fetchWithToken(
        "/api/facility-query-download/list-facility-types-united-only"
      ),
    USE_QUERY_OPTIONS
  );
  const facilitiesQuery = useQuery(
    ["facilities"],
    () =>
      fetchWithToken("/api/facility-query-download/list-facilities-with-types"),
    USE_QUERY_OPTIONS
  );
  const parameterGroupsQuery = useQuery(
    ["parameterGroups"],
    () =>
      fetchWithToken("/api/facility-query-download/q-d-list-parameter-groups"),
    USE_QUERY_OPTIONS
  );

  const [options, setOptions] = useState(DEFAULT_OPTIONS);

  useEffect(() => {
    if (parameterGroupsQuery.data && parameterGroupsQuery.data.length > 0) {
      setOptions((prev) => ({
        ...prev,
        parameterGroups: parameterGroupsQuery.data.map(
          (group) => group.parameter_group_ndx
        ),
      }));
    }
  }, [parameterGroupsQuery.data]);

  const updateOptions = useCallback(
    (key, value) => {
      setOptions((prevOptions) => {
        let newOptions = { ...prevOptions, [key]: value };

        // Clean facilities if facilityTypes are updated
        if (key === "facilityTypes") {
          const validFacilityTypes = new Set(value);
          newOptions.facilities = prevOptions.facilities.filter((facility) =>
            facilitiesQuery.data?.some(
              (fac) =>
                fac.facility_ndx === facility &&
                validFacilityTypes.has(fac.facility_type_ndx)
            )
          );
        }

        return newOptions;
      });
    },
    [facilitiesQuery.data]
  );

  const { mutate, isLoading, error } = useMutation(
    async ({ filename }) => {
      const token = await getAccessTokenSilently();
      const url = `${process.env.REACT_APP_ENDPOINT}/api/facility-query-download/${options.endpoint}`;

      const bodyContent = {
        facilities: options.facilities,
        parameterGroups: options.parameterGroups,
        startDate: options.startDate,
        endDate: options.endDate,
      };

      try {
        const response = await axios.post(url, bodyContent, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          responseType: "blob",
        });

        return { csvContent: response.data, filename };
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: ({ csvContent, filename }) => {
        triggerDownload(csvContent, filename);
        doToast("success", "CSV file successfully added to download folder.");
      },
      onError: (err) => {
        doToast("error", "Failed to download CSV file.");
        console.error(err);
      },
    }
  );

  return {
    handleDownloadData: mutate,
    isLoading,
    error: error,
    inputs: {
      endpoints: {
        data: [
          { value: "data-15min", label: "15 Minutes" },
          { value: "data-daily", label: "Daily" },
        ],
      },
      facilityTypes: {
        data: facilityTypesQuery.data,
        isLoading: facilityTypesQuery.isFetching,
        error: facilityTypesQuery.error,
        refetch: facilityTypesQuery.refetch,
      },
      facilities: {
        data: facilitiesQuery.data,
        isLoading: facilitiesQuery.isFetching,
        error: facilitiesQuery.error,
        refetch: facilitiesQuery.refetch,
      },
      parameterGroups: {
        data: parameterGroupsQuery.data,
        isLoading: parameterGroupsQuery.isFetching,
        error: parameterGroupsQuery.error,
        refetch: parameterGroupsQuery.refetch,
      },
    },
    options,
    setOptions,
    updateOptions,
  };
};

export default useFacilitiesDownload;
