import React, { useCallback, useMemo } from "react";

import CopyIcon from "@material-ui/icons/FileCopy";
import MaterialTable from "material-table";
import { useApp } from "../../../AppProvider";
import { copyToClipboard } from "../../../utils";

const Table = React.memo(
  ({
    title = "",
    isLoading = false,
    data = [],
    pageSize = 120,
    height = "400px",
    filtering = false,
    actions = [],
    options = {},
    columns = [],
  }) => {
    const { doToast } = useApp();

    const defaultOptions = {
      filtering,
      search: false,
      emptyRowsWhenPaging: false,
      exportButton: true,
      exportAllData: true,
      columnsButton: true,
      pageSize,
      pageSizeOptions: [5, 10, 30, 60, 120, 300],
      padding: "dense",
      maxBodyHeight: height,
      showTitle: false,
    };

    const handleCopy = useCallback(() => {
      try {
        copyToClipboard(data, columns, () =>
          doToast("success", "Data was copied to your clipboard.")
        );
      } catch (error) {
        doToast("error", error?.message ?? "Something went wrong");
      }
    }, [data, columns, doToast]);

    const defaultActions = useMemo(
      () => [
        {
          icon: CopyIcon,
          tooltip: "Copy Data",
          isFreeAction: true,
          onClick: handleCopy,
        },
      ],
      [handleCopy]
    );

    return (
      <MaterialTable
        id={title}
        title={title}
        columns={columns}
        isLoading={isLoading}
        data={data}
        actions={[...defaultActions, ...actions]}
        options={{
          ...defaultOptions,
          ...options,
        }}
      />
    );
  }
);

export default Table;
