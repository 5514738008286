import React from "react";
import { Divider as MuiDivider, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Helmet } from "react-helmet-async";
import { styled } from "@material-ui/core/styles";
import StyledImageDisplay from "./StyledImageDisplay";
import useFetchRiverPhoto from "./useFetchRiverPhoto";

const Divider = styled(MuiDivider)(spacing);

const config = {
  pageTitle: "Western Mutual Ditch",
  cameraImageUrl:
    "http://uwsdwesterncamera.hydrologik.net:8080/cgi-bin/api.cgi?cmd=Snap&channel=0&rs=Western&user=UWSD&password=Unitedcam3ra$",
  imageTitle: "Flows at Diversion Dam",
};

const Western = () => {
  const { pageTitle, cameraImageUrl, imageTitle } = config;
  const { imageUrl, isLoading, errorMessage } =
    useFetchRiverPhoto(cameraImageUrl);

  return (
    <>
      <Helmet title={pageTitle} />
      <Typography variant="h3" gutterBottom display="inline">
        {imageTitle}
      </Typography>

      <Divider my={6} />

      <StyledImageDisplay
        imageSrc={imageUrl}
        imageTitle={imageTitle}
        isLoading={isLoading}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default Western;
