import { formatDate } from "./utils";
import { subDays } from "date-fns";

export const BASE_URL = `${process.env.REACT_APP_ENDPOINT}/api`;
export const DATE_FORMAT = "MMM dd yyyy, hh:mm a";

export const TIMESERIES_AXIS_DATE_FORMAT = "MM/DD/yyyy";
export const TIMESERIES_TOOLTIP_FORMAT = "MMM DD yyyy, hh:mm a";

export const LAST_FEW_LINE_CONFIG = {
  xField: "collect_timestamp",
  yField: "result_value",
  idField: "location_ndx",
  unitsField: "unit_desc",
  colorField: "graph_series_color",
  idLabel: "location_id",
};

export const MONTHLY_BAR_CONFIG = {
  xField: "collect_month",
  yField: "ytd_flow_af",
  idField: "location_ndx",
  unitsField: "unit_desc",
  colorField: "graph_series_color",
  idLabel: "location_id",
};

export const ANNUAL_BAR_CONFIG = {
  xField: "year",
  yField: "ytd_flow_af",
  idField: "location_ndx",
  unitsField: "unit_desc",
  colorField: "graph_series_color",
  idLabel: "location_id",
};

export const CROSSTAB_COLUMNS_CONFIG = {
  staticFields: [
    {
      title: "Date/Time",
      field: "collect_timestamp",
      render: (rowData) => formatDate(rowData.collect_timestamp, DATE_FORMAT),
      cellStyle: {
        width: 200,
        minWidth: 200,
        borderLeft: "1px solid rgba(0,0,0,0.12)",
        borderRight: "1px solid rgba(0,0,0,0.12)",
      },
    },
  ],
  dynamicField: {
    dynamicFieldPrefix: "column_label_",
    cellStyle: {
      width: 125,
      minWidth: 125,
    },
  },
  specialRenderPartialFields: {
    "% Full": (field) => (rowData) =>
      rowData[field] ? `${(rowData[field] * 100).toFixed(1)}` : "0",
  },
};

export const TIMESERIES_GRAPH_CONFIG = {
  xField: "collect_timestamp",
  yField: "result_value",
  idField: "legend_label",
  unitsField: "unit_desc",
  color: "graph_series_color",
  lineType: "graph_line_type",
  lineWidth: "graph_line_width",
  seriesType: "graph_series_type",
  areaOpacity: "graph_area_opacity",
  axisLabel: "axis_label",
  suppressLegend: "suppress_legend",
  parameterNdx: "parameter_ndx",
};

export const PARAMETERS_TO_REVERSE_AXIS = [1778]; // groundwater depth

export const TIME_INTERVAL_OPTIONS = [
  {
    label: "15-Minute Data (last 45 days)",
    value: "15min",
  },
  {
    label: "Daily Data (last 365 days)",
    value: "daily",
  },
];

export const QUICKSET_OPTIONS = [
  {
    disableFor: ["15min"],
    label: "Last 365 Days",
    calculateStartDate: () => subDays(new Date(), 365),
  },
  {
    disableFor: ["15min"],
    label: "Last 90 Days",
    calculateStartDate: () => subDays(new Date(), 90),
  },
  {
    label: "Last 45 Days",
    calculateStartDate: () => subDays(new Date(), 45),
  },
  {
    label: "Last 30 Days",
    calculateStartDate: () => subDays(new Date(), 30),
  },
  {
    label: "Last 10 Days",
    calculateStartDate: () => subDays(new Date(), 10),
  },
  {
    label: "Last 3 Days",
    calculateStartDate: () => subDays(new Date(), 3),
  },
];

export const DEFAULT_QUERY_OPTIONS = {
  keepPreviousData: false,
  refetchOnWindowFocus: false,
};

export const ECCV_WELLFIELD_FACILITY_NDX = "29";
const MILLIKEN_WATER_QUALITY_FACILITY_NDX = "38";

export const LINE_CHART_SELECT_ONLY_ONE_LOCATION = [
  ECCV_WELLFIELD_FACILITY_NDX,
];

export const LINE_CHART_SELECT_ONLY_ONE_PARAMETER = [
  MILLIKEN_WATER_QUALITY_FACILITY_NDX,
];

export const TABLE_SELECT_ONLY_ONE_PARAMETER = [ECCV_WELLFIELD_FACILITY_NDX];
