import React from "react";
import LineChart from "../../components/widgets/LineChart";
import { DataLoader, GridItem, WidgetContainer } from "../../styledComponents";
import { Typography } from "@material-ui/core";

const LineChartSection = React.memo(({ data, isLoading, customWrap }) => {
  const allDataNull = data?.every((dataset) =>
    dataset.data.every((item) => {
      return item.y === null;
    })
  );

  return (
    <DataLoader isLoading={isLoading} isData={!!data?.length}>
      <GridItem customWrap={customWrap}>
        <WidgetContainer title="Last 6 hours (hover for detail)">
          {allDataNull ? (
            <Typography style={{ margin: "auto" }}>
              No data available
            </Typography>
          ) : (
            <LineChart data={data} />
          )}
        </WidgetContainer>
      </GridItem>
    </DataLoader>
  );
});

export default LineChartSection;
