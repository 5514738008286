import React from "react";
import { DataLoader } from "../../styledComponents";
import FacilityMap from "./FacilityMap";
import styled from "styled-components/macro";

const MapContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  height: 100%;
  min-height: 244px;
`;

const MapSection = React.memo(({ data, isLoading }) => {
  const {
    fp_mapcenter_lat: lat,
    fp_mapcenter_lon: lon,
    fp_mapzoom_level: zoom,
  } = data || {};

  return (
    <DataLoader isLoading={isLoading} isData={!!data}>
      <MapContainer>
        <FacilityMap lat={lat} lon={lon} zoom={zoom} />
      </MapContainer>
    </DataLoader>
  );
});

export default MapSection;
