import React, { useState } from "react";
import { styled } from "@material-ui/core/styles";
import {
  Box,
  Paper,
  Typography,
  Modal as MuiModal,
  Backdrop,
  Fade,
} from "@material-ui/core";
import Loader from "../../components/Loader";
import { Alert } from "@material-ui/lab";

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[800],
  color: theme.palette.common.white,
  padding: theme.spacing(3),
  textAlign: "center",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const ImageContainer = styled("div")(({ theme }) => ({
  cursor: "pointer",
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: "0px 6px 12px rgba(0,0,0,0.5)",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  transition: "box-shadow 0.3s ease-in-out",
  "&:hover": {
    boxShadow: "0 12px 18px rgba(0,0,0,.9)",
  },
}));

const StyledImg = styled("img")({
  width: "100%",
  height: "auto",
  maxWidth: "800px",
});

const StyledModal = styled(MuiModal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const ModalBox = styled(Box)(({ theme }) => ({
  outline: "none",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: "90vw",
  maxHeight: "90vh",
  overflow: "auto",
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
}));

const ModalStyledImg = styled("img")({
  maxHeight: "80vh",
  maxWidth: "100%",
});

const ModalTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  marginTop: theme.spacing(2),
}));

const StyledImageDisplay = ({
  imageSrc,
  imageTitle,
  isLoading,
  errorMessage,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <StyledPaper elevation={3}>
      <StyledBox>
        <Typography variant="h4" gutterBottom>
          {imageTitle}
        </Typography>
        {isLoading ? (
          <Box p={10}>
            <Loader />
          </Box>
        ) : !!errorMessage ? (
          <Alert severity="error">
            <Typography variant="h6" gutterBottom>
              {errorMessage}
            </Typography>
            <Typography variant="subtitle2">
              Please contact the administrator.
            </Typography>
          </Alert>
        ) : (
          <ImageContainer onClick={handleOpen}>
            <StyledImg src={imageSrc} alt={imageTitle} />
          </ImageContainer>
        )}
      </StyledBox>
      <StyledModal
        aria-labelledby="image-modal-title"
        aria-describedby="image-modal-description"
        open={open}
        onClick={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <ModalBox>
            <ModalStyledImg src={imageSrc} alt={imageTitle} />
            <ModalTitle variant="h5">{imageTitle}</ModalTitle>
          </ModalBox>
        </Fade>
      </StyledModal>
    </StyledPaper>
  );
};

export default StyledImageDisplay;
