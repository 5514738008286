import { addMonths } from "date-fns";

export const CSV_DATE_TIME_FORMAT = "MMM dd yyyy, hh:mm a";

export const DEFAULT_OPTIONS = {
  startDate: addMonths(new Date(), -3),
  endDate: new Date(),
  endpoint: "data-daily",
  facilityTypes: [1], //augmentation
  facilities: [],
  parameterGroups: [],
};

export const USE_QUERY_OPTIONS = {
  keepPreviousData: false,
  refetchOnWindowFocus: false,
};

export const FILENAME = `UnitedWaterData-${new Date()}.csv`;
