import React from "react";

import { Box, Grid, Link as MuiLink, Typography } from "@material-ui/core";
import TableSection from "./TableSection";
import Options from "./Options";
import { BorderBox } from "../../styledComponents";
import { useTableData } from "../../hooks/useTableData";
import { useLineGraphData } from "../../hooks/useLineGraphData";
import { useParameters } from "../../hooks/useParameters";
import { useLocations } from "../../hooks/useLocations";
import { useDaysRange } from "../../hooks/useDaysRange";
import LineGraphSection from "./LineGraphSection";
import { useTimeInterval } from "../../hooks/useTimeInterval";
import { Link } from "react-router-dom";
import { LINE_CHART_SELECT_ONLY_ONE_PARAMETER } from "../../constants";

const CurrentWeekDataAnalysis = React.memo(
  ({
    facilityNdx,
    yRMin,
    yRMax,
    associatedLocations,
    associatedParameters,
  }) => {
    const { daysRange, handleDaysRange } = useDaysRange();

    const { timeInterval, handleTimeInterval } = useTimeInterval(
      daysRange,
      handleDaysRange
    );

    const {
      parametersTable,
      handleParametersTable,
      parametersGraph,
      handleParametersGraph,
      parametersOptions,
    } = useParameters({
      facilityNdx,
      associatedParameters,
    });

    const { locations, handleLocations, locationsOptions } = useLocations({
      facilityNdx,
      associatedLocations,
    });

    const tableData = useTableData({
      facilityNdx,
      timeInterval,
      daysRange,
    });

    const lineGraphData = useLineGraphData({
      facilityNdx,
      timeInterval,
      daysRange,
    });

    const error = [tableData, lineGraphData].find((query) => query.error);

    if (error) {
      return <p>Error fetching current week data analysis: {error.message}</p>;
    }

    return (
      <BorderBox>
        <Box mb="10px">
          <Typography variant="h5">Data Drill Down</Typography>
          <Typography variant="subtitle1">
            recent data (use the{" "}
            <MuiLink
              component={Link}
              to="/data-access/query-and-download/facilities"
            >
              Query & Download
            </MuiLink>{" "}
            to access older data)
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TableSection
              data={tableData.data}
              isLoading={tableData.isFetching}
              selectedParameters={parametersTable || []}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <LineGraphSection
              data={lineGraphData.data}
              isLoading={lineGraphData.isFetching}
              selectedItems={
                LINE_CHART_SELECT_ONLY_ONE_PARAMETER.includes(facilityNdx)
                  ? parametersGraph
                  : locations || []
              }
              timeInterval={timeInterval}
              yRMin={yRMin}
              yRMax={yRMax}
            />
          </Grid>
          <Grid item xs={12}>
            <Options
              facilityNdx={facilityNdx}
              timeInterval={timeInterval}
              handleTimeInterval={handleTimeInterval}
              daysRange={daysRange}
              handleDaysRange={handleDaysRange}
              parametersOptions={parametersOptions}
              parametersTable={parametersTable}
              handleParametersTable={handleParametersTable}
              parametersGraph={parametersGraph}
              handleParametersGraph={handleParametersGraph}
              locationsOptions={locationsOptions}
              locations={locations}
              handleLocations={handleLocations}
            />
          </Grid>
        </Grid>
      </BorderBox>
    );
  }
);

export default CurrentWeekDataAnalysis;
