import React from "react";
import { DataLoader, GridItem, WidgetContainer } from "../../styledComponents";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const TICK_STYLES = {
  fill: "rgb(51, 51, 51)",
  fontSize: "11px",
  fontFamily: "sans-serif",
};

const CurrentValuesBarChartSection = React.memo(
  ({ data, isLoading, customWrap }) => {
    return (
      <DataLoader isLoading={isLoading} isData={!!data?.length}>
        <GridItem
          customWrap={customWrap}
          md={12}
          lg={12}
          xl={12}
          xxl={"100%"}
          xxxl={"100%"}
        >
          <WidgetContainer title="Current Values by Well">
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                height={200}
                data={data}
                margin={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 20,
                }}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis
                  axisLine={false}
                  dataKey="location_id"
                  type={"category"}
                  interval={0}
                  tick={TICK_STYLES}
                  tickMargin={20}
                  angle={90}
                />
                <YAxis
                  domain={[0, "auto"]}
                  yAxisId="left"
                  axisLine={false}
                  type={"number"}
                  tick={TICK_STYLES}
                  padding={{ top: 10 }}
                />
                <YAxis
                  domain={[0, "auto"]}
                  hide
                  orientation={"right"}
                  yAxisId="right"
                  type={"number"}
                  padding={{ top: 10 }}
                />
                <Tooltip />
                <Bar
                  yAxisId="left"
                  dataKey={"current_level_ft"}
                  fill="rgb(78, 121, 167)"
                  name={"Current Level"}
                  stroke={"rgb(38, 59, 82)"}
                  strokeWidth={2}
                  unit={" GPM"}
                  radius={2}
                />
                <Line
                  yAxisId="right"
                  dataKey="current_gpm"
                  dot={{
                    fill: "rgb(255, 87, 34)",
                    stroke: "rgb(38, 59, 82)",
                    strokeWidth: 2,
                    r: 5,
                  }}
                  name={"Current Pumping"}
                  strokeWidth={0}
                  stroke="#ff7300"
                  unit={" ft"}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </WidgetContainer>
        </GridItem>
      </DataLoader>
    );
  }
);

export default CurrentValuesBarChartSection;
