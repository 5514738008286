import React from "react";
import {
  Divider as MuiDivider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper as MuiPaper,
  Select,
} from "@material-ui/core";
import styled from "styled-components/macro";
import QuickSetButtons from "../../components/QuickSetButtons";
import {
  LINE_CHART_SELECT_ONLY_ONE_LOCATION,
  LINE_CHART_SELECT_ONLY_ONE_PARAMETER,
  TABLE_SELECT_ONLY_ONE_PARAMETER,
  TIME_INTERVAL_OPTIONS,
} from "../../constants";
import { spacing } from "@material-ui/system";
import Loader from "../../../../components/Loader";

const Paper = styled(MuiPaper)(spacing);
const Divider = styled(MuiDivider)(spacing);

const OptionsGridContainer = styled(Grid).attrs({
  container: true,
})`
  justify-content: space-between;
  align-items: center;
`;

const OptionsGridItem = styled(Grid).attrs({
  item: true,
  xs: 12,
  md: "auto",
})`
  display: flex;
  justify-content: center;
`;

const SelectControl = React.memo(
  ({ label, id, value, onChange, options, isLoading, multiple = false }) => (
    <FormControl margin="normal" variant="outlined" fullWidth>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        multiple={multiple}
        value={value}
        onChange={onChange}
        label={label}
      >
        {isLoading ? (
          <MenuItem disabled>
            <Loader />
          </MenuItem>
        ) : (
          options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  )
);

const Options = React.memo(
  ({
    facilityNdx,
    timeInterval,
    handleTimeInterval,
    daysRange,
    handleDaysRange,
    parametersOptions,
    parametersTable,
    handleParametersTable,
    parametersGraph,
    handleParametersGraph,
    locationsOptions,
    locations,
    handleLocations,
  }) => {
    const showParametersTableSelect =
      TABLE_SELECT_ONLY_ONE_PARAMETER.includes(facilityNdx);
    const showParametersGraphSelect =
      LINE_CHART_SELECT_ONLY_ONE_PARAMETER.includes(facilityNdx);
    const showLocationsSelect =
      LINE_CHART_SELECT_ONLY_ONE_LOCATION.includes(facilityNdx);

    const selectControlsConfig = [
      {
        label: "Time Interval (table and graph)",
        id: "timeInterval",
        value: timeInterval,
        onChange: handleTimeInterval,
        options: TIME_INTERVAL_OPTIONS,
      },
      showLocationsSelect && {
        label: "Locations (graph)",
        id: "locations",
        multiple: true,
        value: locations ?? [],
        onChange: handleLocations,
        options: locationsOptions,
      },
      showParametersTableSelect && {
        label: "Parameters (table)",
        id: "parametersTable",
        multiple: true,
        value: parametersTable ?? [],
        onChange: handleParametersTable,
        options: parametersOptions,
      },
      showParametersGraphSelect && {
        label: "Parameters (graph)",
        id: "parametersGraph",
        multiple: true,
        value: parametersGraph ?? [],
        onChange: handleParametersGraph,
        options: parametersOptions,
      },
    ].filter(Boolean);

    return (
      <Paper p={3}>
        <OptionsGridContainer>
          <Grid container spacing={3}>
            {selectControlsConfig.map((config, index) => (
              <Grid key={index} item xs={12} md={6}>
                <SelectControl {...config} />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Divider mb={4} />
            </Grid>
          </Grid>
          <OptionsGridItem>
            <QuickSetButtons
              daysRange={daysRange}
              handleDaysRange={handleDaysRange}
              timeInterval={timeInterval}
            />
          </OptionsGridItem>
        </OptionsGridContainer>
      </Paper>
    );
  }
);

export default Options;
