import React, { useMemo } from "react";
import { useQuery } from "react-query";
import useFetchData from "../../../hooks/useFetchData";
import { Divider as MuiDivider, Typography } from "@material-ui/core";
import styled from "styled-components/macro";
import Table from "../../../components/Table";
import { spacing } from "@material-ui/system";
import Panel from "../../../components/panels/Panel";
import { Helmet } from "react-helmet-async";
import Loader from "../../../components/Loader";
import { getLookupForDataSortedByField } from "../../../utils";
import axios from "axios";
import { formatDate } from "../../facilities/utils";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  max-height: calc(100% - 48px);
`;

const Divider = styled(MuiDivider)(spacing);

//388px
const SitesSummaryTable = () => {
  const { data, isFetching, error } = useQuery(
    ["/api/reports-site-param-summary-united-stations"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/reports-site-param-summary-united-stations`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const [Facilities] = useFetchData("list-facilities-with-types", [], true);
  const facilitiesLookup = useMemo(() => {
    let converted = {};
    if (Facilities?.length > 0) {
      Facilities.forEach((item) => {
        converted[item.facility_name] = item.facility_name;
      });
    }
    return converted;
  }, [Facilities]);

  if (error) return "An error has occurred: " + error.message;

  const tabColumns = [
    {
      title: "Alert",
      field: "is_alert",
    },
    {
      title: "Facility Type",
      field: "facility_type_desc",
      lookup: getLookupForDataSortedByField(data, "facility_type_desc"),
    },
    {
      title: "Facility Name",
      field: "facility_name",
      lookup: facilitiesLookup,
    },
    {
      title: "Parameter",
      field: "parameter",
      lookup: getLookupForDataSortedByField(data, "parameter"),
    },
    {
      title: "Location Name",
      field: "location_name",
    },
    {
      title: "POR Start",
      field: "start_por",
      render: (rowData) => {
        return formatDate(rowData.start_por, "MM/dd/yyyy");
      },
    },
    {
      title: "POR End",
      field: "end_por",
      render: (rowData) => {
        return formatDate(rowData.end_por, "MM/dd/yyyy, h:mm a");
      },
    },
    {
      title: "County",
      field: "county_name",
      lookup: getLookupForDataSortedByField(data, "county_name"),
    },
    {
      title: "Water Body",
      field: "waterbody_name",
      lookup: getLookupForDataSortedByField(data, "waterbody_name"),
    },
    {
      title: "Watershed",
      field: "huc12_name",
      lookup: getLookupForDataSortedByField(data, "huc12_name"),
    },
  ];
  return (
    <>
      <Helmet title="Site Summary Table" />
      <Typography variant="h3" gutterBottom display="inline">
        Site Summary Table
      </Typography>

      <Divider my={6} />
      <Panel>
        <TableWrapper>
          {data ? (
            <Table
              label="Site Summary Table"
              isLoading={isFetching}
              columns={tabColumns}
              data={data}
              height="calc(100vh - 375px)"
              pageSize={60}
              options={{ filtering: true }}
            />
          ) : (
            <Loader />
          )}
        </TableWrapper>
      </Panel>
    </>
  );
};
export default SitesSummaryTable;
