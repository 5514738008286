export const DUMMY_BASEMAP_LAYERS = [
  { url: "satellite-streets-v11", icon: "satellite_alt" },
  { url: "streets-v11", icon: "commute" },
  { url: "outdoors-v11", icon: "park" },
];

export const UNITED_LOCATIONS_LAYER = {
  id: "united-locations-circle",
  name: "🔒 United Infrastructure",
  type: "circle",
  source: "united-locations",
  drawOrder: -122,
  legendOrder: 122,
  paint: {
    "circle-color": ["get", "map_sym_fill_color"],
    "circle-radius": 8,
    "circle-stroke-width": 2,
    "circle-stroke-color": ["get", "map_sym_stroke_color"],
  },
  layout: {
    visibility: "visible",
  },
};

export const UNITED_LOCATIONS_LABELS_LAYER = {
  id: "united-locations-symbol",
  name: "🔒 United Infrastructure Labels",
  type: "symbol",
  source: "united-locations",
  minzoom: 8,
  drawOrder: -121,
  legendOrder: 121,
  layout: {
    "text-field": [
      "format",
      ["get", "location_name"],
      {},
      "\n",
      {},
      ["get", "location_id"],
      {
        "text-font": ["literal", ["DIN Offc Pro Italic"]],
        "font-scale": 0.85,
      },
    ],
    "text-size": 14,
    "text-offset": [0, -2.75],
    "text-font": ["literal", ["Open Sans Bold", "Arial Unicode MS Bold"]],
    visibility: "visible",
  },
  paint: {
    "text-color": "#1a1a1a",
    "text-halo-color": "rgba(255, 255, 255, 0.9)",
    "text-halo-width": 2,
    "text-halo-blur": 0.1,
  },
};
