export const BASEMAP_STYLES = [
  {
    style: "outdoors-v11",
    name: "Outdoors",
    url: "mapbox://styles/mapbox/outdoors-v11",
  },
  {
    style: "satellite-streets-v11",
    name: "Satellite",
    url: "mapbox://styles/mapbox/satellite-streets-v11",
  },
  {
    style: "streets-v11",
    name: "Streets",
    url: "mapbox://styles/mapbox/streets-v11",
  },
  {
    style: "light-v10",
    name: "Light",
    url: "mapbox://styles/mapbox/light-v10",
  },
  {
    style: "dark-v10",
    name: "Dark",
    url: "mapbox://styles/mapbox/dark-v10",
  },
];

/**
 * Array of layer IDs that will have custom symbols for the map, driven by attributes in the underlying model.
 *
 * Layers in this array must have the following fields to drive the custom symbols:
 * map_sym_shape, map_sym_fill_color, map_sym_stroke_color, map_sym_pixel_size, map_sym_stroke_width, map_sym_legend_pixel_size
 *
 * @constant
 * @type {string[]}
 * @default
 */
export const LAYERIDS_WITH_CUSTOM_SYMBOLS = [
  "dwr-diversion",
  "dwr-streamflow",
  "dwr-reservoir",
  "rivercalls",
  "usds-spr-current",
];

export const DEFAULT_MAP_CENTER = [-104.77843, 40.26692];

export const INIT_MAP_CONFIG = {
  style: BASEMAP_STYLES[0].url,
  center: DEFAULT_MAP_CENTER,
  zoom: 8.4,
  preserveDrawingBuffer: true,
};

export const WELLS_LAYER_ID = "united-locations-circle";
export const WELLS_LABELS_LAYER_ID = "united-locations-symbol";
export const INIT_FILTER_VALUES = {
  reportingStatus: {
    label: "Reporting Status",
    name: "reportingStatus",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "reporting_status_desc",
    options: [],
    type: "multi-select",
    value: [],
  },
  locationTypes: {
    label: "Location Types",
    name: "locationTypes",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "locationtype",
    options: [],
    type: "multi-select",
    value: [],
  },
};

const layerId = "united-locations-circle";
export const INIT_STYLE_VALUES = {
  default: {
    id: "default",
    layerId,
    layerFieldName: "",
    name: "Default",
    paint: {
      "circle-color": "#0000FF",
    },
  },
  locationTypes: {
    id: "locationTypes",
    layerId,
    layerFieldName: "locationtype",
    name: "Location Types",
    options: [],
    type: "multi-select",
    value: [],
  },
};
