import React from "react";

import { useFacilities } from "./hooks/useFacilities";
import Facility from "./[id]";

const Facilities = () => {
  const {
    data: facilities,
    isLoading,
    error,
    selectedFacility,
    handleSelectFacility,
  } = useFacilities();

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error fetching facilities: {error.message}</p>;
  }

  return (
    <div>
      <h2>Facilities List</h2>
      <table style={{ marginBottom: "20px" }}>
        <thead>
          <tr>
            <th>Facility Name</th>
          </tr>
        </thead>
        <tbody>
          {facilities.map((facility) => (
            <tr key={facility.facility_ndx}>
              <td>{facility.facility_ndx}</td>
              <td>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleSelectFacility(facility);
                  }}
                >
                  {facility.facility_name}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {!!selectedFacility && <Facility selectedFacility={selectedFacility} />}
    </div>
  );
};

export default Facilities;
