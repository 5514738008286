import React, { useMemo, useRef } from "react";

import { Chart, Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import zoomPlugin from "chartjs-plugin-zoom";

import {
  TIMESERIES_AXIS_DATE_FORMAT,
  TIMESERIES_TOOLTIP_FORMAT,
} from "../../constants";
import { TableWrapper, TimeseriesContainer } from "../../styledComponents";
import SaveGraphButton from "../../../../components/graphs/SaveGraphButton";
import ZoomResetButton from "../ZoomResetButton";
import {
  annotatedVerticalLinePlugin,
  chartBackgroundPlugin,
} from "./ChartPlugins";

Chart.register(zoomPlugin);

const setColorOpacity = (color, opacity) =>
  color.replace(/[\d.]+\)$/g, `${opacity})`);

const storeOriginalColors = (dataset) => {
  if (!dataset.originalBackgroundColor) {
    dataset.originalBackgroundColor = dataset.backgroundColor;
  }
  if (!dataset.originalBorderColor) {
    dataset.originalBorderColor = dataset.borderColor;
  }
};

const restoreOriginalColors = (dataset) => {
  dataset.backgroundColor = dataset.originalBackgroundColor;
  dataset.borderColor = dataset.originalBorderColor;
};

const setHoverColors = (dataset, isHovered) => {
  dataset.backgroundColor = setColorOpacity(
    dataset.originalBackgroundColor,
    isHovered ? 0.9 : 0.25
  );
  dataset.borderColor = setColorOpacity(
    dataset.originalBorderColor,
    isHovered ? 0.9 : 0.25
  );
};

const TimeseriesLineChartDashboard = React.memo(
  ({
    data,
    isLoading,
    yLLabel = null,
    yRLabel = null,
    yLMin = null,
    yLMax = null,
    yRMin = null,
    yRMax = null,
    yLReverseAxis = false,
    yRReverseAxis = false,
    timeInterval = "15min",
  }) => {
    const ref = useRef(null);
    const options = useMemo(
      () => ({
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: "index",
        },
        plugins: {
          legend: {
            onHover: (evt, hoveredItem, legend) => {
              legend.chart.data.datasets.forEach((dataset, index) => {
                storeOriginalColors(dataset);
                setHoverColors(dataset, index === hoveredItem.datasetIndex);
              });
              legend.chart.update();
            },
            onLeave: (evt, hoveredItem, legend) => {
              legend.chart.data.datasets.forEach(restoreOriginalColors);
              legend.chart.update();
            },
            labels: {
              filter: function (legendItem, chartData) {
                const dataset = chartData.datasets[legendItem.datasetIndex];
                return !dataset.suppressLegend;
              },
              usePointStyle: true,
            },
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItems) {
                return `${tooltipItems.dataset.label}: ${tooltipItems.formattedValue} ${tooltipItems.dataset.units}`;
              },
            },
          },
          zoom: {
            pan: {
              enabled: true,
              mode: "xy",
              modifierKey: "ctrl",
            },
            zoom: {
              mode: "xy",
              drag: {
                enabled: true,
                borderColor: "rgb(54, 162, 235)",
                borderWidth: 1,
                backgroundColor: "rgba(54, 162, 235, 0.3)",
              },
            },
          },
        },
        scales: {
          x: {
            type: "time",
            time: {
              unit: "day",
              displayFormats: {
                day: TIMESERIES_AXIS_DATE_FORMAT,
              },
              tooltipFormat:
                timeInterval === "15min"
                  ? TIMESERIES_TOOLTIP_FORMAT
                  : TIMESERIES_AXIS_DATE_FORMAT,
            },
            grid: {
              display: false,
            },
            ticks: {
              maxTicksLimit: 9,
            },
          },
          yL: {
            display: !!yLLabel,
            title: {
              display: true,
              text: yLLabel,
            },
            grid: {
              borderDash: [10, 5],
              drawBorder: !!yLLabel,
              color: "#cbcbcb",
            },
            min: yLMin,
            max: yLMax,
            reverse: yLReverseAxis,
          },
          yR: {
            position: "right",
            display: !!yRLabel,
            title: {
              display: true,
              text: yRLabel,
            },
            grid: {
              borderDash: [5, 10],
              drawBorder: !!yLLabel,
              color: "#d3d3d3",
            },
            min: yRMin,
            max: yRMax,
            reverse: yRReverseAxis,
          },
        },
        onClick(e) {
          const chart = e.chart;
          chart.options.plugins.zoom.zoom.wheel.enabled =
            !chart.options.plugins.zoom.zoom.wheel.enabled;
          chart.update();
        },
      }),
      [yLLabel, yRLabel, yLMin, yLMax, yRMin, yRMax] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
      <TimeseriesContainer>
        <TableWrapper>
          <Line
            plugins={[chartBackgroundPlugin, annotatedVerticalLinePlugin]}
            ref={ref}
            data={data}
            options={options}
            type="line"
            style={{ cursor: "pointer" }}
          />
        </TableWrapper>
        <div style={{ display: "flex" }}>
          <ZoomResetButton resetZoom={() => ref?.current?.resetZoom()} />
          <SaveGraphButton
            ref={ref}
            title={`15-Minute Data`}
            disabled={isLoading || !data?.datasets?.length}
          />
        </div>
      </TimeseriesContainer>
    );
  }
);
export default TimeseriesLineChartDashboard;
