import React from "react";
import { Helmet } from "react-helmet-async";

import { useParams } from "react-router-dom";
import RecentDataSummary from "../sections/recentDataSummary/RecentDataSummary";
import RecentDataSummaryEccv from "../sections/recentDataSummary/RecentDataSummaryEccv";
import { useFacilities } from "../hooks/useFacilities";
import {
  Divider as MuiDivider,
  Grid,
  Typography as MuiTypography,
  Breadcrumbs as MuiBreadcrumbs,
  Link,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import MapSection from "../sections/map/MapSection";
import CurrentWeekDataAnalysis from "../sections/currentWeekDataAnalysis/CurrentWeekDataAnalysis";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { ROUTES } from "../../../constants";
import { ECCV_WELLFIELD_FACILITY_NDX } from "../constants";

const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Facility = React.memo(({ selectedFacility }) => {
  const { id: routeId } = useParams();
  const effectiveId = selectedFacility?.facility_ndx || routeId;

  const { data, isLoading, error } = useFacilities(effectiveId);

  if (error) {
    return <p>Error fetching facility data: {error.message}</p>;
  }

  return (
    <>
      <Helmet title="Facility Details" />

      <Typography variant="h3" gutterBottom display="inline">
        {data?.facility}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to={ROUTES.PUBLIC_MAP}>
          Dashboard
        </Link>
        <Link component={NavLink} exact to="/facility-pages">
          Facilities
        </Link>
        <Typography>{data?.facility}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={3}>
        <Grid item xs={12} md={5} lg={4} xl={3}>
          <MapSection data={data} isLoading={isLoading} />
        </Grid>

        <Grid item xs={12} md={7} lg={8} xl={9}>
          {effectiveId === ECCV_WELLFIELD_FACILITY_NDX ? (
            <RecentDataSummaryEccv facilityNdx={effectiveId} />
          ) : (
            <RecentDataSummary
              widgetOrder={data?.widget_order || []}
              facilityNdx={effectiveId}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <CurrentWeekDataAnalysis
            facilityNdx={effectiveId}
            yRMin={data?.axis2_min}
            yRMax={data?.axis2_max}
            associatedLocations={data?.array_location_ndx}
            associatedParameters={data?.array_parameter_ndx}
          />
        </Grid>
      </Grid>
    </>
  );
});

export default Facility;
