import React, { useState, useEffect } from "react";
import axios from "axios";
import { Typography, Grid, Box, Chip } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import useFetchData from "../../../hooks/useFetchData";
import useFormSubmitStatus from "../../../hooks/useFormSubmitStatus";
import { Flex } from "../../../components/Flex";
import FormSnackbar from "../../../components/FormSnackbar";
import SearchableList from "../../../components/SearchableList";
import styled from "styled-components/macro";
import AssociationControls from "./AssociationControls";
import FacilitiesListForLocationsManagementTable from "./FacilitiesListForLocationsManagementTable";

const Root = styled.div`
  margin-top: 6px;
  width: 100%;
`;

const FacilitiesToLocationsAssoc = () => {
  const [refreshSwitch, setRefreshSwitch] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { setWaitingState, snackbarOpen, snackbarError, handleSnackbarClose } =
    useFormSubmitStatus();

  const [activeFacility, setActiveFacility] = useState({});
  const [associatedLocations, setAssociatedLocations] = useState([]);
  const [Facilities] = useFetchData("list-facilities-with-types", [
    refreshSwitch,
  ]);
  const [Locations] = useFetchData(`list-locations-united-only`, []);
  const [Associations] = useFetchData(`assoc-locations-facilities`, [
    refreshSwitch,
  ]);

  useEffect(() => {
    const activeAssociations = Associations.filter(
      (d) => activeFacility.facility_ndx === d.facility_ndx
    ).map((d) => d.location_ndx);
    if (activeAssociations.length > 0 && activeAssociations[0] !== null) {
      setAssociatedLocations(activeAssociations);
    } else {
      setAssociatedLocations([]);
    }
  }, [Associations, activeFacility, refreshSwitch]);

  const handleRefresh = () => {
    setRefreshSwitch((state) => !state);
  };

  const handleFacilitiesSelect = (facility) => {
    setAssociatedLocations([]);
    setActiveFacility(facility);
  };

  const handleLocationsSelect = (rowData, row) => {
    const value = row.location_ndx;
    const checked = row.tableData.checked;
    setAssociatedLocations((prevState) => {
      let newValues = [...prevState];
      if (checked) {
        newValues.push(+value);
      } else {
        const index = newValues.indexOf(+value);
        newValues.splice(index, 1);
      }
      return newValues;
    });
  };

  const handleSelectNone = () => setAssociatedLocations([]);

  const handleSelectAll = () =>
    setAssociatedLocations(Locations.map((d) => d.location_ndx));

  const prepareValues = () => {
    return associatedLocations.map((associatedLocation) => {
      return {
        facility_ndx: activeFacility.facility_ndx,
        location_ndx: associatedLocation,
      };
    });
  };

  const handleSubmit = () => {
    // Set up a cancellation source
    let didCancel = false;
    setWaitingState("in progress");
    async function writeData() {
      try {
        const token = await getAccessTokenSilently();

        const headers = { Authorization: `Bearer ${token}` };

        await axios.put(
          `${process.env.REACT_APP_ENDPOINT}/api/assoc-locations-facilities/${activeFacility.facility_ndx}`,
          prepareValues(),
          { headers }
        );
        if (!didCancel) {
          console.log("success");
          setWaitingState("complete", "no error");
          handleRefresh();
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
        } else {
          console.error(err);
          setWaitingState("complete", "error");
        }
        didCancel = true;
      }
    }
    writeData();
  };

  return (
    <Root>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={3}>
          <SearchableList
            title="Facilities"
            data={Facilities}
            valueField="facility_ndx"
            primaryDisplayField="facility"
            active={activeFacility}
            onClick={handleFacilitiesSelect}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Box marginTop={2} width="100%">
            <Flex>
              <Typography variant="h6" color="textSecondary" gutterBottom>
                Manage Facilities Associations:
              </Typography>

              {activeFacility.facility && (
                <Box marginTop={2} marginBottom={2} marginLeft={2}>
                  <Chip label={activeFacility.facility} />
                </Box>
              )}
            </Flex>

            {activeFacility.facility && (
              <AssociationControls
                handleSave={handleSubmit}
                handleSelectAll={handleSelectAll}
                handleSelectNone={handleSelectNone}
              />
            )}

            {activeFacility.facility ? (
              <FacilitiesListForLocationsManagementTable
                selections={associatedLocations}
                onCheck={handleLocationsSelect}
                refreshSwitch={refreshSwitch}
              />
            ) : (
              <>
                <Typography variant="body1" paragraph>
                  Select a facility from the left to associate them with
                  locations.
                </Typography>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      <FormSnackbar
        open={snackbarOpen}
        error={snackbarError}
        handleClose={handleSnackbarClose}
        successMessage="Associations successfully saved."
        errorMessage="Associations could not be saved."
      />
    </Root>
  );
};

export default FacilitiesToLocationsAssoc;
