import { useAuth0 } from "@auth0/auth0-react";

export const useAuthHeaders = () => {
  const { getAccessTokenSilently } = useAuth0();

  const getHeaders = async () => {
    try {
      const token = await getAccessTokenSilently();
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    } catch (error) {
      console.error("Error fetching access token:", error);
      return { headers: {} };
    }
  };

  return getHeaders;
};
