import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import { useSubmitData } from "../hooks/useSubmitData";
import { useFacilityInfo } from "../hooks/useFacilityInfo";
import { getButtonIcon, getButtonText } from "../utils";
import Loader from "../../../../components/Loader";
function FacilityInfo({
  selectedFacility,
  setSelectedFacility,
  refetchFacilities = () => {},
  isEditMode = false,
}) {
  const {
    isLoading,
    error,
    handleUpdateState,
    handleResetState,
    state,
    refetch,
    isDirty,
    lookups,
  } = useFacilityInfo(selectedFacility);
  const { handleSubmit, isFormSubmitting } = useSubmitData(
    selectedFacility,
    setSelectedFacility,
    state,
    "data-list-facilities",
    "facility_ndx",
    refetch,
    refetchFacilities
  );

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading ||
        (isEditMode && !state?.facility_ndx && (
          <Paper style={{ width: "100%", margin: "8px 0" }}>
            <Loader />
          </Paper>
        ))}
      <Accordion
        defaultExpanded
        style={{
          display:
            isLoading || (isEditMode && !state?.facility_ndx)
              ? "none"
              : "block",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box style={{ width: "100%" }}>
            <Typography variant="h3">Facility Information</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sm={6}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  error={!state.facility_name}
                  variant="outlined"
                  label="Facility Name"
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    handleUpdateState("facility_name", e.target.value)
                  }
                  value={state.facility_name ?? ""}
                />
                {!state.facility_name && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                error={!state.facility_type_ndx}
                required
              >
                {!lookups?.facilityTypes.length > 0 ? (
                  <Loader />
                ) : (
                  <>
                    <InputLabel id="facility-type">Facility Type</InputLabel>
                    <Select
                      labelId="facility type"
                      id="facility-type"
                      label="Facility Type"
                      name="facility_type_ndx"
                      value={state.facility_type_ndx ?? ""}
                      onChange={(e) => {
                        handleUpdateState("facility_type_ndx", e.target.value);
                      }}
                    >
                      {lookups.facilityTypes.map((option) => (
                        <MenuItem
                          key={option.facility_type_desc}
                          value={option.facility_type_ndx}
                        >
                          {option.facility_type_desc}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                {!state.facility_type_ndx && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              style={{
                position: "relative",
              }}
            >
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <TextField
                  error={!state.display_order}
                  variant="outlined"
                  label="Display Order"
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    handleUpdateState("display_order", e.target.value)
                  }
                  value={state.display_order ?? ""}
                />
                {!state.display_order && (
                  <FormHelperText error>*Required field</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button size="small" onClick={handleResetState}>
            Reset
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleSubmit}
            disabled={!isDirty || !state.facility_name || isFormSubmitting}
            startIcon={getButtonIcon(isFormSubmitting)}
            style={{ width: "100px" }}
          >
            {getButtonText(isFormSubmitting, selectedFacility === 0)}
          </Button>
        </AccordionActions>
      </Accordion>
    </>
  );
}

export default FacilityInfo;
