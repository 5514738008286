import React from "react";
import Button from "@material-ui/core/Button";
import { QUICKSET_OPTIONS } from "../constants";
import styled from "styled-components/macro";

// Create a styled button with conditional styling based on the selected state
const StyledButton = styled(Button)`
  &:not(:first-child) {
    margin-left: 8px;
  }
  ${(props) =>
    props.selected &&
    `
    background-color: rgba(0, 123, 255, 0.1); // Slightly lighter background
    color: rgba(0, 123, 255, 0.9); // Slightly darker text color
  `}
`;

export const QuickSetButton = ({
  option,
  timeInterval,
  daysRange,
  handleDaysRange,
}) => {
  const isSelected = daysRange?.label === option.label;
  return (
    <StyledButton
      disabled={option.disableFor?.includes(timeInterval)}
      onClick={() => handleDaysRange(option)}
      type="submit"
      color="primary"
      variant="contained"
      size="small"
      aria-label={option.label}
      selected={isSelected}
    >
      {option.label}
    </StyledButton>
  );
};

const QuickSetButtons = ({ timeInterval, daysRange, handleDaysRange }) => {
  return QUICKSET_OPTIONS.map((option, i) => (
    <QuickSetButton
      key={i}
      option={option}
      timeInterval={timeInterval}
      daysRange={daysRange}
      handleDaysRange={handleDaysRange}
    />
  ));
};

export default QuickSetButtons;
