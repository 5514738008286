import { useCallback, useState } from "react";
import { QUICKSET_OPTIONS, TIME_INTERVAL_OPTIONS } from "../constants";

export const useTimeInterval = (daysRange, handleDaysRange) => {
  const [timeInterval, setTimeInterval] = useState(
    TIME_INTERVAL_OPTIONS[0].value
  );

  const handleTimeInterval = useCallback(
    ({ target: { value } }) => {
      if (daysRange.disableFor?.includes(value)) {
        handleDaysRange(QUICKSET_OPTIONS[QUICKSET_OPTIONS.length - 1]);
      }
      setTimeInterval(value);
    },
    [daysRange, handleDaysRange]
  );

  return {
    timeInterval,
    setTimeInterval,
    handleTimeInterval,
  };
};
