import { format } from "date-fns";
// import colors from "d3-scale-chromatic/src/colors";
const Color = require("color");
const colorNames = require("color-name");

export const monthLookup = Array.from({ length: 12 }, (_, i) => ({
  abbrev: format(new Date(0, i), "MMM"), // Abbreviated month name
  full: format(new Date(0, i), "MMMM"), // Full month name
})).reduce((acc, curr, index) => {
  acc[index + 1] = curr; // Adjust the index to 1-12
  return acc;
}, {});

// export const colorScale = colors(
//   "4e79a7FF5722e1575976b7b259a14fedc949af7aa1ff9da79c755fbab0ab"
// );

export const getContrastYIQ = (rgbColor) => {
  const rgbValues = rgbColor.match(/\d+/g).map(Number); // Extract RGB values
  const [r, g, b] = rgbValues;
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
};

export const getLocationKeys = (data, prefix) =>
  Array.from(
    data.reduce((keys, item) => {
      Object.keys(item).forEach(
        (key) => key.startsWith(prefix) && keys.add(key)
      );
      return keys;
    }, new Set())
  );

export const formatDate = (value, dateFormat = "MM/dd/yy ' @ ' hh:mm a") =>
  format(new Date(value), dateFormat);

export const transformDataForLastFourLineChart = ({
  data,
  xField,
  yField,
  idField,
  unitsField,
  colorField,
  idLabel,
  dateFormat = "MM/dd/yy ' @ ' hh:mm a",
}) => {
  if (!data) return [];

  const seriesMap = data.reduce((acc, item) => {
    const {
      [xField]: x,
      [yField]: y,
      [idField]: id,
      [unitsField]: units,
      [colorField]: color,
      [idLabel]: label,
      ...rest
    } = item;

    if (!acc[id]) {
      acc[id] = { id, color, data: [] };
    }

    acc[id].data.push({
      x: formatDate(x, dateFormat),
      y,
      units,
      label,
      ...rest,
    });

    return acc;
  }, {});

  return Object.values(seriesMap);
};

// const assignColorToIds = (data, idField) => {
//   const uniqueLocations = [...new Set(data.map((d) => d[idField]))];
//   return uniqueLocations.reduce((acc, loc, i) => {
//     acc[loc] = colorScale[i % colorScale.length];
//     return acc;
//   }, {});
// };

export const transformDataForBarChart = ({
  data,
  xField,
  yField,
  idField,
  unitsField,
  colorField,
  idLabel,
}) => {
  if (!data || data.length === 0) return [];

  // Assign unique colors to unique ids
  // const colorMap = assignColorToIds(data, idField);

  // Aggregate data with respect to a given field and assign colors
  const aggregatedData = data.reduce((acc, item) => {
    const key = `${xField}_${item[xField]}`;
    if (!acc[key])
      acc[key] = {
        [xField]: item[xField],
        units: item[unitsField],
      };

    const locationKey = `id_${item[idField]}`;
    const colorKey = `color_${locationKey}`;
    const labelKey = `label_${locationKey}`;
    acc[key][locationKey] = item[yField];
    acc[key][colorKey] = item[colorField];
    acc[key][labelKey] = item[idLabel];
    acc[key]["collect_year"] = item["collect_year"];
    return acc;
  }, {});

  return Object.values(aggregatedData);
};

const toRgba = (color, opacity) => {
  try {
    // Check if the color is a named CSS color
    if (colorNames[color.toLowerCase()]) {
      // Convert named color to RGB values
      const [r, g, b] = colorNames[color.toLowerCase()];
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }

    // If not a named color, try to convert it using the Color library
    const rgbColor = Color(color).rgb().array();
    return `rgba(${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]}, ${opacity})`;
  } catch (error) {
    // Catch and log any errors that occur (e.g., if an invalid color is provided)
    console.error(`Invalid color: ${color}`);
    return null;
  }
};
export const createTimeseriesDataset = ({
  location,
  yAxisId,
  config: {
    xField,
    yField,
    unitsField,
    seriesType,
    lineWidth,
    idField,
    color,
    areaOpacity,
    lineType,
    suppressLegend,
    axisLabel,
    parameterNdx,
  },
}) => {
  const firstLocation = location?.[0] ?? {};
  const isArea = firstLocation[seriesType] === "area";
  const borderColor = firstLocation[color] || "#000";
  const backgroundColor = isArea
    ? toRgba(borderColor, firstLocation[areaOpacity])
    : toRgba(borderColor, 0.8);

  return {
    data: location.map((item) => ({
      x: item[xField],
      y: item[yField],
    })),
    yAxisID: yAxisId,
    units: firstLocation[unitsField],
    pointStyle: isArea ? "rect" : "line",
    hoverPointStyle: "circle",
    fill: isArea,
    borderWidth: firstLocation[lineWidth],
    spanGaps: true,
    pointRadius: 0,
    pointHoverRadius: 4,
    label: firstLocation[idField],
    borderColor: toRgba(borderColor, 1.0),
    backgroundColor,
    tension: 0.5,
    borderDash: firstLocation[lineType],
    suppressLegend: firstLocation[suppressLegend],
    axisLabel: firstLocation[axisLabel],
    parameter: firstLocation[parameterNdx],
  };
};
