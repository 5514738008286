import {
  Card,
  CardContent,
  Grid,
  CardHeader,
  Box,
  Typography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import React from "react";
import Loader from "../../components/Loader";

const media = (theme, breakpoint, styles) => `
  @media (min-width: ${theme.breakpoints.values[breakpoint]}px) {
    ${styles}
  }
`;

export const ScrollableGridContainer = styled(({ customWrap, ...rest }) => (
  <Grid {...rest} />
))`
  ${({ theme, customWrap }) =>
    customWrap &&
    media(
      theme,
      "lg",
      `
      flex-wrap: nowrap;
      overflow-x: auto;
    `
    )}
`;

const StyledGridItem = styled(({ customWrap, ...rest }) => <Grid {...rest} />)`
  ${({ theme, customWrap }) =>
    customWrap &&
    media(
      theme,
      "lg",
      `
    flex-grow: 0;
    flex-shrink: 0;
  `
    )}
  ${({ theme, xxl }) =>
    media(
      theme,
      "xxl",
      `
    max-width: ${xxl};
    flex-basis: ${xxl};
  `
    )}
  ${({ theme, xxxl }) =>
    media(
      theme,
      "xxxl",
      `
    max-width: ${xxxl};
    flex-basis: ${xxxl};
  `
    )}
`;

export const GridItem = ({
  children,
  xs = 12,
  sm = 12,
  md = 12,
  lg = 6,
  xl = 4,
  xxl = "25%",
  xxxl = "20%",
  customWrap = false,
  ...rest
}) => {
  return (
    <StyledGridItem
      item
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      xxl={xxl}
      xxxl={xxxl}
      customWrap={customWrap}
      {...rest}
    >
      {children}
    </StyledGridItem>
  );
};

export const TooltipTypography = styled(Typography).attrs({
  variant: "body2",
})``;

const StyledCard = styled(Card)`
  overflow: inherit;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledCardContent = styled(CardContent)`
  max-height: ${({ contentheight, footer, footerheight }) =>
    !footer ? contentheight : "calc(" + contentheight + ` - ${footerheight})`};
  flex: 1 1 ${({ contentheight }) => contentheight};
  display: flex;
  justify-content: center;
`;

const LoadingBox = styled(Box)`
  height: ${({ contentheight }) => contentheight};
  min-height: 200px;
  display: flex;
  justify-content: center;
`;

export const WidgetContainer = ({
  children,
  title = "",
  contentHeight = "200px",
  footer = null,
  footerheight = "27.5px",
}) => {
  return (
    <StyledCard>
      <CardHeader title={title} />
      <StyledCardContent
        contentheight={contentHeight}
        footer={footer}
        footerheight={footerheight}
      >
        {children}
      </StyledCardContent>
      {footer && (
        <StyledCardContent style={{ height: footerheight, padding: 0 }}>
          <Typography variant="h6">{footer}</Typography>
        </StyledCardContent>
      )}
    </StyledCard>
  );
};

export const LoadingContainer = ({ children, contentHeight = "100%" }) => {
  return (
    <GridItem>
      <LoadingBox contentheight={contentHeight}>{children}</LoadingBox>
    </GridItem>
  );
};

export const DataLoader = ({ isLoading = false, isData, children }) => {
  if (isLoading) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  if (!isData) {
    return <React.Fragment />;
  }

  return children;
};

export const ChartTooltipContainer = styled(Box)`
  color: ${({ color }) => color};
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  padding: 10px;
  border-radius: 5px;
  max-width: 100%;
  white-space: nowrap;
`;

export const SeriesTooltipContainer = styled(Box)`
  background-color: ${({ backgroundcolor }) => backgroundcolor};
  color: ${({ color }) => color};
  padding: 5px;
  margin: 5px 0;
`;

export const TableWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  max-width: 100%;
  width: 100%;
`;

export const TimeseriesContainer = styled.div`
  flex-grow: 1;
  min-height: 400px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const BorderBox = styled(Box)`
  border: 2px solid darkgray;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
`;

export const HeaderBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const FooterBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
