import { useFetch } from "./useFetch";
import { LINE_CHART_SELECT_ONLY_ONE_PARAMETER } from "../constants";

export const useLineGraphData = ({ facilityNdx, timeInterval, daysRange }) => {
  const groupIdKey = LINE_CHART_SELECT_ONLY_ONE_PARAMETER.includes(facilityNdx)
    ? "parameter_ndx"
    : "location_ndx";
  const lineGraphData = useFetch({
    endpoint: `get-display-facility/graph-${timeInterval}/${facilityNdx}`,
    queryKey: [facilityNdx, daysRange, timeInterval, "graph"],
    params: `${daysRange
      .calculateStartDate()
      .toISOString()}/${new Date().toISOString()}/${groupIdKey}`,
    options: {
      onError: (error) => console.error(error),
      enabled: !!facilityNdx,
    },
  });

  return lineGraphData;
};
