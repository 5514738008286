import axios from "axios";
import { BASE_URL, DEFAULT_QUERY_OPTIONS } from "../constants";
import { useQuery } from "react-query";
import { useAuthHeaders } from "./useAuthHeaders";

export const useFetch = ({
  endpoint,
  queryKey = null,
  params = "",
  options = {},
}) => {
  const getHeaders = useAuthHeaders();

  const queryFn = async () => {
    const { headers } = await getHeaders();

    const { data } = await axios.get(`${BASE_URL}/${endpoint}/${params}`, {
      headers,
    });
    return data;
  };

  const queryArray = queryKey === null ? [endpoint] : [queryKey];
  return useQuery(queryArray, queryFn, {
    onError: (error) => console.error(error),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  });
};
