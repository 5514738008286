import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useCallback, useState } from "react";

export const useFacilities = (facilityNdx = null) => {
  const { getAccessTokenSilently } = useAuth0();

  const [selectedFacility, setSelectedFacility] = useState(null);

  const handleSelectFacility = useCallback(
    (facility) => {
      setSelectedFacility(facility);
    },
    [setSelectedFacility]
  );

  const fetchFacilities = useCallback(
    async ({ queryKey }) => {
      const [_key, { ndx }] = queryKey; // eslint-disable-line no-unused-vars
      const endpoint = ndx
        ? `/api/display-list-facilities/${ndx}`
        : `/api/display-list-facilities`;
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}${endpoint}`,
        { headers }
      );
      return response.data;
    },
    [getAccessTokenSilently]
  );

  const { data, isFetching, error } = useQuery(
    ["display-list-facilities", { ndx: facilityNdx }],
    fetchFacilities,
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    data,
    isLoading: isFetching,
    error,
    selectedFacility,
    setSelectedFacility,
    handleSelectFacility,
  };
};
