import {
  transformDataForBarChart,
  transformDataForLastFourLineChart,
} from "../utils";
import { useMemo } from "react";
import {
  ANNUAL_BAR_CONFIG,
  LAST_FEW_LINE_CONFIG,
  MONTHLY_BAR_CONFIG,
} from "../constants";

const useTransformedData = (data, transformFunction, config) =>
  useMemo(
    () =>
      data?.data ? transformFunction({ data: data.data, ...config }) : null,
    [data?.data, transformFunction, config]
  );

export const useTransformedFacilityData = ({
  lastFewData,
  monthlyData,
  ytdData,
}) => {
  const transformedLastFewData = useTransformedData(
    lastFewData,
    transformDataForLastFourLineChart,
    LAST_FEW_LINE_CONFIG
  );
  const transformedMonthlyData = useTransformedData(
    monthlyData,
    transformDataForBarChart,
    MONTHLY_BAR_CONFIG
  );
  const transformedYtdData = useTransformedData(
    ytdData,
    transformDataForBarChart,
    ANNUAL_BAR_CONFIG
  );
  return { transformedLastFewData, transformedMonthlyData, transformedYtdData };
};
