import React from "react";
import { DataLoader, GridItem, WidgetContainer } from "../../styledComponents";
import BarChart from "../../components/widgets/BarChart";
import { ANNUAL_BAR_CONFIG } from "../../constants";

const AnnualBarChartSection = React.memo(({ data, isLoading, customWrap }) => {
  return (
    <DataLoader isLoading={isLoading} isData={!!data?.length}>
      <GridItem customWrap={customWrap}>
        <WidgetContainer title="Current Water-year (Apr-Mar) to Date, AF">
          <BarChart data={data} indexBy={ANNUAL_BAR_CONFIG.xField} />
        </WidgetContainer>
      </GridItem>
    </DataLoader>
  );
});

export default AnnualBarChartSection;
