import { useFetch } from "./useFetch";

export const useTableData = ({ facilityNdx, timeInterval, daysRange }) => {
  const tableData = useFetch({
    endpoint: `get-display-facility/table-${timeInterval}/${facilityNdx}`,
    queryKey: [facilityNdx, daysRange, timeInterval, "table"],
    params: `${daysRange
      .calculateStartDate()
      .toISOString()}/${new Date().toISOString()}`,
    options: {
      onError: (error) => console.error(error),
      enabled: !!facilityNdx,
    },
  });

  return tableData;
};
