import axios from "axios";
import { titleize } from "inflected";
import { format } from "date-fns";
import { CSV_DATE_TIME_FORMAT } from "./facilities/constants";

export const formatDate = (value, dateFormat = CSV_DATE_TIME_FORMAT) =>
  format(new Date(value), dateFormat);

export const fetchData = async (url, token) => {
  const headers = { Authorization: `Bearer ${token}` };
  try {
    const { data } = await axios.get(url, { headers });
    return data;
  } catch (error) {
    throw new Error(`Failed to fetch data: ${error.message}`);
  }
};

export const formatHeaderToTitleCase = (header) => {
  // replace underscores with spaces to handle snake_case
  let formattedHeader = header.replace(/_/g, " ");
  // insert a space before capital letters in camelCase strings, avoiding the first character
  formattedHeader = formattedHeader.replace(/([a-z])([A-Z])/g, "$1 $2");
  // apply titleize to convert to title case
  return titleize(formattedHeader);
};

export const convertToCSV = (data) => {
  if (!data.length) return "";

  // Extract headers and format them to title case
  const headers = Object.keys(data[0]);
  const formattedHeaders = headers.map((header) =>
    formatHeaderToTitleCase(header)
  );

  // Map each row of data to a CSV string
  const rows = data.map((row) =>
    headers
      .map((header) => {
        let value = row[header];

        if (header === "collect_timestamp") {
          value = formatDate(value); // Example format: January 1, 2020, 12:00 AM
        }
        // If value contains a comma, newline or double-quote, escape the entire string
        const needsEscaping = /[",\n]/.test(value);
        const escapedValue = needsEscaping
          ? `"${value.replace(/"/g, '""')}"`
          : value;
        return escapedValue;
      })
      .join(",")
  );

  // Combine headers and rows, each joined with commas and separated by newlines
  const csvContent = [formattedHeaders.join(","), ...rows].join("\n");
  return csvContent;
};

export const triggerDownload = (
  blob,
  filename = `data_download_${new Date()}.csv`
) => {
  const blobUrl = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(blobUrl);
};
